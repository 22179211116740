/** RADIO **/
.kr-field.kr-radio {
  cursor: pointer;
  margin-bottom: 16px;
  height: 33px;
  width: 100%;

  .kr-radio-control-indicator {
    cursor: pointer;
    display: block;
    height: 13px;
    width: 13px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #717171;
    margin-left: 5px;
    z-index: 1;

    .kr-checked {
      display: block;
      margin: 2px;
      height: 9px;
      width: 9px;
      border-radius: 50%;
      background-color: #fff;
    }
  }

  input[type="radio"] {
    cursor: pointer;
    position: fixed;
    opacity: 0;
    margin-left: 5px;
    height: 13px;
    width: 13px;
    z-index: 2;
    padding: 0;

    &:checked + .kr-radio-control-indicator .kr-checked {
      background-color: #717171;
    }
  }

  label {
    cursor: pointer;
    width: 100%;
    text-align: justify;
    margin-left: 8px;
  }
}
