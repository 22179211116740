@use "sass:math";

@mixin -single-column() {
  grid-column: 1 / span 2;
}

// Used for both SmartForm & Grid
@mixin wallet($gap: 10px) {
  $columns: 2;

  .kr-smart-form-wallet--compact {
    display: grid;
    grid-template-columns: repeat($columns, calc(50% - ($gap / 2)));
    gap: $gap;

    .kr-smart-form-wallet-card {
      margin: 0;

      &:last-of-type:nth-of-type(odd) {
        @include -single-column;
      }
    }
  }
}

// Only SmartForm (no grid)
@mixin list-options($gap: 10px) {
  &.kr-methods-list-options--compact {
    &:not(.kr-methods-list-options--extra) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $gap;
    }

    .kr-methods-list-row {
      @include -single-column;
    }

    .kr-methods-list-options-item {
      width: 100%;
      min-width: 0;
      margin: 0;

      &:last-of-type:nth-of-type(odd) {
        @include -single-column;
      }

      .kr-wallet-expanded {
        margin: 0;
      }

      .kr-method-label,
      .kr-methods-group-activator {
        margin: 0;

        &:not(.kr-apple_pay,.kr-paypal,.kr-paypal_sb,.kr-paypal_bnpl,.kr-paypal_bnpl_sb) {
          padding-left: 14px;
        }

        .kr-method-label-wrapper {
          overflow: hidden;
          padding-left: 1px;
        }

        label {
          white-space: break-spaces;
          text-overflow: ellipsis;
        }
      }
    }

    .kr-form-error {
      @include -single-column;
    }
    @content;
  }
}
