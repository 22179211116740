.kr-smart-form-wrapper {
  &.kr-type-embedded,
  &.kr-type-popin {
    .kr-extras-form-content {
      padding: 15px;
    }
  }

  &.kr-type-popin .kr-extras-form-content {
    padding-top: 0;
  }

  &.kr-type-embedded .kr-embedded.kr-extras-form-content {
    width: 330px;
  }

  &.kr-type-popin {
    span.kr-extras-form-header {
      padding: 0 20px 20px;
    }
  }

  span.kr-extras-form-header {
    width: 330px;
    padding: 20px 20px 5px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: $color-primary;
  }
}
