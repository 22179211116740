.kr-icon {
  position: relative;

  .kr-icon-wrapper {
    display: none;
  }
}

.kr-brand-selector .kr-brand-selector-wrapper ul.kr-brand-selector-list {
  display: block;
}

.kr-icon-arrow {
  display: none;
}
