@use "sass:color";
@use "../../../mixins/smartform/radio-button";
@use "../../../mixins/smartform/button";
@use "../variables" as *;
@use "../wallet";
@use "./card-icons";

@mixin delete-button-hover() {
  &.kr-smart-form-wallet-card--hover-delete:not(.kr-locked) {
    border-color: $color-error;

    &.kr-method-label--spbtn {
      border-color: $color-error;
    }

    &.kr-method-label--selected {
      background-color: color.scale($color-error, $lightness: 90%);
      border-color: $color-error;
    }

    @include radio-button.main(
      $size: $smartform-radio-button-size,
      $border-color: #e3e3e3,
      $border-color-hover: $color-error,
      $active-color: $color-error
    );

    .kr-delete-button-wrapper {
      .kr-delete-button .kr-delete-icon svg path {
        fill: $color-error;
      }
    }
  }

  .kr-delete-button-wrapper.kr-disabled {
    cursor: not-allowed;

    .kr-delete-icon {
      cursor: not-allowed;
    }
  }
}

@mixin card() {
  .kr-smart-form-wallet-card {
    display: flex;

    &.kr-smart-form-wallet-card--alert {
      @include button.alert-button-animation;
    }

    @include card-icons.brands($container-element: none);
    @include wallet.delete-button-main;
    @include delete-button-hover;

    .kr-brand-icon.kr-cards,
    .kr-brand-icon:not(.kr-cards) {
      margin-right: 12px;
    }

    label {
      width: 0;
      flex-grow: 1;
    }

    .kr-delete-button-wrapper {
      padding: 0 21px;
    }

    .kr-loading-animation {
      margin-right: 20px;
    }
  }
}

@mixin label() {
  &.kr-smart-form-wallet-card {
    padding-right: 0;
  }
}

@mixin create-token() {
  &.kr-delete-button--create-token {
    .kr-delete-button-wrapper {
      .kr-delete-button .kr-delete-icon svg path {
        fill: $color-error;
      }
    }
  }

  &.kr-method-label--create-token-selected {
    border-color: $color-error;
    background-color: #f9e9e9;
  }
}

@mixin main() {
  .kr-smart-form-wallet {
    display: block;

    @include card;
  }
}
