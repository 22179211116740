.kr-smart-button-wrapper {
  display: block;
  margin: 10px 0;

  @import "./../error";
  @import "./error";
  @import "./skeleton";
}

/* KJS-2563: Must be outside to allow the styling with just .kr-smart-button selector */
@import "./button";
