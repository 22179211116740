@use "./common";

@mixin list-button() {
  @include common.list-button;

  .kr-methods-group-activator,
  .kr-method-label {
    width: 100%;
    transition: 0.3s background-color;

    .kr-method-icon {
      left: 15px;
      height: 100%;
    }

    .kr-loading-animation {
      position: absolute;
      right: 20px;
    }
  }
}

@mixin group() {
  @include common.group;

  .kr-methods-group {
    .kr-methods-group-activator {
      label {
        flex-grow: 1;
        text-align: end;
      }
    }
  }
}
