@use "../../wallet";
@use "../header";
@use "../footer";
@use "./back-button";
@use "../../smartform/methods-list";

// Smartform modal
.kr-smart-form-modal-wrapper {
  .kr-smart-form-modal {
    min-width: $smartform-popin-width;
    padding: $smartform-popin-padding;
    box-shadow: $smartform-popin-box-shadow;
    background-color: transparent;

    @include header.smartform;
    @include methods-list.popin;
    @include methods-list.popin-one-scroll;
    @include back-button.popin;
    @include back-button.popin-iframe;
    @include wallet.card-list-embedded;
    @include wallet.tabs-embedded;
    @include footer.smartform;

    // With the iframe open
    &.kr-secure-iframe-visible {
      padding-top: 0;
      padding-bottom: 0;
      border: 5px solid #f4f4f4;
      border-radius: 5px;
      background-color: #f4f4f4;

      @import "./mobile";
    }

    &.kr-smart-form-modal--scrollable-content {
      max-height: 100%;
    }
  }
}
