@use "sass:math";

$border-radius: 5px;

// Font
$font-family: roboto, sans-serif;
$font-size: 14px;
$color-font: #444;

// Card fields
$field-width: 250px;
$field-height: 40px;
$icon-width: 44px;
$help-width: 50px;

// Form
$form-width: $field-width + $help-width;
$inner-width: $field-width + $help-width - 15px;

// Button
$button-width: 232px;
$button-height: 50px;

// Popin
$header-height: 100px;
$shop-name-width: 275px;

// SmartForm
$smartform-width: 300px;
$smartform-grid-width: 350px;
$smartform-compact-width: 425px;
$smartform-header-height: 58px;
$smartform-radio-button-size: 20px;
$smartform-methods-gap: 10px;
$smartform-section-margin: 10px;
$smartform-border-color: #b7b7b7;

// Smarform list options
$smartform-option-height: 50px;
$font-color-disabled: #999;
$border-color-disabled: #ddd;
$icons-box-shadow: rgb(0, 0, 0, 7%) 0 6px 24px 0, rgb(0, 0, 0, 24%) 0 0 0 1px;

// Cards
$card-elements-gap: 15px;

// Single payment button
$smartform-spb-width: 340px;
$smartform-spb-radio-border-color: #e3e3e3;

// SmartForm Popin
$smartform-popin-width: 330px;
$smartform-popin-border: 5px;
$smartform-popin-border-radius: 5px;
$smartform-popin-margin-x: 15px;
$smartform-popin-padding: 106px 0 70px 0;
$smartform-grid-popin-padding: 110px 0 70px 0;
$smartform-popin-box-shadow: rgb(0, 0, 0, 30%) 0 19px 38px,
  rgb(0, 0, 0, 22%) 0 15px 12px;

// SmartButton
$smartbutton-width: 300px;
$smartbutton-spb-width: $smartform-spb-width;

// Colors
$color-input-font: #888;
$color-input-placeholder: #999;
$color-grey-icons: #777;
$color-grey-icons-hover: #666;
$color-light: #fff;
$color-primary: var(--kr-global-color-primary, #293c7a);
$color-primary-mid-light: #6e7aa2;
$color-primary-light: #a9b4ca;
$color-secondary: #d97706;
$color-grey-border: #ccc;
$color-hover: #eee;
$color-light-secondary: #f4f4f4;
$color-disabled: #eee;
$color-warning: #f9a825;
$color-error: #c62828;
