@use "sass:color";
@use "../radio-button";

@mixin delete-btn(
  $icon-fill,
  $padding: 20px,
  $icon-height: 20px,
) {
  .kr-delete {
    height: 100%;
    padding: 0 $padding;

    svg {
      width: auto;
      height: $icon-height;

      path {
        fill: $icon-fill;
      }
    }
  }
}

@mixin hover-delete(
  $color-error,
  $smartform-radio-button-size,
  $smartform-spb-radio-border-color
) {
  &--hover-delete {
    border-color: $color-error;

    &.kr-method-btn--spbtn {
      border-color: $color-error;

      @include radio-button.main(
        $size: $smartform-radio-button-size,
        $border-color: $smartform-spb-radio-border-color,
        $border-color-hover: $color-error,
        $active-color: $color-error
      );
    }

    &.kr-method-btn--selected {
      background-color: color.scale($color-error, $lightness: 90%);
      border-color: $color-error;
    }

    .kr-delete svg path {
      fill: $color-error;
    }
  }
}
