@use "./popin/header";
@use "./popin/footer";
@use "./variables" as var;

/* Popin CSS */
.kr-popin-utils .kr-popin-background {
  background-color: #232323;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9998;
}

.kr-popin-button {
  background-color: $color-primary;
  color: #fff;
}

.kr-popin-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  left: -9999px;
  position: fixed;
  top: -9999px;
  z-index: 9999;
  overflow: auto;
  min-height: 0;

  &--large-form {
    align-items: flex-start;
    padding: 10px 0;
  }

  .kr-embedded[kr-popin] {
    display: flex;
    flex-direction: column;

    &.kr-embedded-flex-display-row-wrap {
      flex-flow: row wrap;
    }

    .kr-payment-button {
      background-color: $color-primary;
      color: #fff;
    }
  }

  &.kr-secure-iframe-open {
    z-index: 10000;
  }

  @mixin mobile-format() {
    @include align-items(start);

    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (max-width: 410px) {
    @include mobile-format;
  }

  @media (max-width: 800px) and (max-height: 500px) {
    @include mobile-format;
  }

  @media (max-width: 350px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.kr-embedded[kr-popin] {
  box-sizing: border-box;
  background-color: #fff;
  padding-bottom: $footer-height;
  padding-top: $header-height + 28px + 1px;
  position: relative;

  @include header.main;
  @include footer.main;

  &[krvue].kr-secure-iframe-open {
    padding: 30px 0 0;
    width: auto;
    height: auto;
  }
}

/* 3ds mobile */
@media (max-width: 450px) {
  .kr-embedded.kr-secure-iframe-open[kr-popin][krvue] {
    bottom: 0;
    left: 0;
    margin-top: 0;
    padding-top: 0;
    position: absolute;
    right: 0;
    top: 0;

    .kr-secure-iframe-visible.kr-popin-modal-header {
      height: 37px;
    }

    iframe#krcheckout {
      bottom: 0 !important;
      height: 100% !important;
      left: 0 !important;
      position: fixed !important;
      right: 0 !important;
      top: 37px !important;
      width: 100% !important;
    }
  }
}

/* iOS10 */
.kr-embedded.kr-ios-10[kr-popin],
.kr-embedded.kr-ios-9[kr-popin] {
  .kr-popin-modal-header .kr-popin-modal-header-image {
    margin-left: 70px;
  }
}
