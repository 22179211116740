@use "./popin/header";

@mixin -header() {
  @include header.main;

  .kr-popin-modal-header {
    position: relative;
  }
}

@mixin -overlay() {
  .kr-background {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: #232323;
    opacity: 0.7;
  }
}

@mixin -modal() {
  .kr-popin-redirection-modal {
    z-index: 1;

    &.kr-popin-redirection-modal--mobile {
      position: fixed;
      left: 0;
      top: 0;
      border-left: 0;
      border-right: 0;
    }
  }
}

@mixin main() {
  .kr-popin-redirection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @include -overlay;
    @include -header;
    @include -modal;
  }
}
