@use "../../mixins/form";

// Hidden (out of view)
$default-order-field: 20;
$default-order-control: 30;

.kr-embedded,
.kr-smart-form {
  &.kr-out-of-view-form,
  &.kr-out-of-view-form[krvue],
  &.kr-out-of-view-form[krvue][kr-popin] {
    position: fixed;
    top: -4000px;
    left: -4000px;
    right: -4000px;
    bottom: -4000px;
  }
}

.kr-embedded {
  display: block;

  /**
    * Ugly but necessary to take priority over style.display changes within JS
    *
    * @since KJS-4019
    */
  &--no-card {
    display: none !important;
  }

  &.kr-embedded-flex-display {
    display: flex;
    flex-wrap: wrap;

    &.kr-embedded-flex-direction-column {
      flex-direction: column;
    }

    &.kr-embedded-flex-direction-row {
      flex-flow: row wrap;
    }
  }

  @include form.all-fields {
    position: relative;
    order: 0;
  }

  /**
   * Keep regular fields undisplayed until they have class "kr-field-element".
   * That is until component are mounted and their v-show condition takes over
   * the display condition.
   *
   * @since KJS-2419
   */
  @include form.regular-fields {
    &:not(.kr-field-element) {
      display: none;
    }
  }

  @include form.custom-fields {
    width: 100%;
  }

  .kr-form-error,
  .kr-payment-button,
  .kr-discount-panel--container,
  .kr-discount-panel-wrapper {
    order: $default-order-control;
  }
}

// KJS-4596 - Apply only to SmartForm to maintain legacy retro-compatibility
.kr-embedded.kr-custom-order {
  @include form.all-fields {
    order: $default-order-field;
  }
}

// Rows
.kr-embedded[krvue] {
  .kr-expiry.kr-size-half,
  .kr-security-code.kr-size-half {
    width: calc(50% - 5px);
  }
}
