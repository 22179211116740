@mixin main($size: 16px) {
  .kr-sp-radio-button {
    --circle-graph-color: var(--kr-sp-radio-color);

    height: $size;
    width: $size;
    cursor: pointer;

    &--checked {
      --circle-graph-color: white;
    }
  }
}
