@use "../../variables" as *;
@use "../../popin/header";
@use "../../popin/footer";
@use "../../popin/smartform/back-button";
@use "../../scroll";
@use "../../fonts";
@use "./extras-form";

$content-height: 355px;
$spacing: 15px;
$view-padding: 10px $spacing;
$cards-view-padding: $spacing $spacing 0 $spacing;
$view-label-padding: $spacing + 5px $spacing;

@mixin wrapper() {
  .kr-sf-modal-wrapper {
    @include modal;
  }
}

@mixin modal() {
  .kr-modal {
    min-width: $smartform-popin-width;
    padding: $smartform-grid-popin-padding;
    background-color: transparent;

    @include header.smartform;
    @include content;
    @include error;
    @include footer.smartform;

    // Variants
    @include iframe;
  }
}

@mixin content() {
  .kr-modal-content {
    background-color: $color-light;
    width: $smartform-popin-width;
    min-height: $content-height;

    &--content {
      height: auto;
    }

    @include view;
  }
}

@mixin view() {
  &__view {
    // Views with limited height
    &--full {
      height: $content-height;
    }

    @include view-label;
    @include view-content;
    @include back-button.popin-grid;
    @include scroll.main;
    @include extras-form.content-embedded;

    &--redirection {
      @include back-button.redirect-back-button;
    }
  }
}

@mixin view-label() {
  .kr-modal-view-label {
    @include fonts.default;

    padding: $view-label-padding;
    color: $color-primary;
  }
}

@mixin view-content() {
  .kr-modal-view-content {
    padding: $view-padding;
    gap: $smartform-methods-gap;

    &--no-button {
      padding: $cards-view-padding;
    }

    .kr-partial-payment-panel {
      margin-top: 10px;
    }
  }

  // Full view - it has the label above
  &--full .kr-modal-view-content {
    padding: 0 $spacing 10px $spacing;

    // Wallet has it's own label inside
    .kr-sf-wallet {
      padding-top: $spacing;
    }

    // register has its own label inside
    .kr-sf-cards--register {
      padding-top: $spacing;
    }
  }
}

@mixin iframe() {
  &--iframe-visible {
    padding: 0;
    border: $smartform-popin-border-radius solid $color-light-secondary;
    border-radius: $smartform-popin-border-radius;
    background-color: $color-light-secondary;

    .kr-modal-content {
      padding: 0;
    }
  }
}

@mixin error() {
  &__error {
    padding: $spacing $spacing 0 $spacing;

    .kr-form-error {
      padding: 0;
    }
  }
}
