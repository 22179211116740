@import "./variables";

$font-sm: 12px;
$font-md: 14px;
$font-lg: 16px;
$font-xl: 17px;
$font-thick: 500;
$font-thicker: 600;
$font-dark: #111827;
$font-light: #666;
$font-lighter: #999;

@mixin default() {
  font-family: $font-family;
  font-size: $font-size;
  color: $color-font;
}

@mixin md-thick() {
  font-family: $font-family;
  font-size: $font-md;
  font-weight: $font-thicker;
  color: $font-dark;
}
