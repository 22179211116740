@import "./variables";

// Default css variable values, overridden by the store
@mixin root() {
  :root {
    --kr-form-button-backgroundColor: $color-primary;
    --kr-form-button-color: $color-light;
    --kr-form-button-borderColor: $color-primary;
  }
}
