@use "./method-icons";

@mixin main() {
  .kr-method-tooltip-content {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 1;
    opacity: 0;

    &.kr-visible {
      top: 0;
      opacity: 1;

      @include method-icons.visible;
    }

    &.kr-on-top {
      top: -53px;
    }

    .kr-card-tooltip,
    .kr-methods-group-tooltip {
      position: absolute;
      top: 0;
      background-color: #fff;
      display: flex;
      flex-flow: row nowrap;

      span svg {
        cursor: default;
      }
    }
  }
}
