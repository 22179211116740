$size: 14px;

@mixin main() {
  .kr-back-button {
    cursor: pointer;

    @include back-icon;
  }
}

@mixin back-icon() {
  &__icon {
    height: 100%;
    padding: 0 $size;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      transform: rotate(180deg);
      width: $size;
      height: $size;
      transition: color 0.3s;
    }
  }
}
