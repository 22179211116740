@use "../card-header";

@mixin section() {
  .kr-sf-header {
    display: flex;
    flex-direction: column;
  }

  @include card-header.main;
}
