@use "./method-option" as option;
@use "./back-button";
@use "../card-icons";

@mixin section() {
  .kr-sf-opm {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    &__methods {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      overflow: hidden;

      @include group;
    }
  }
}

@mixin group() {
  $transition-time: 0.5s;

  .kr-opm-group {
    display: grid;
    grid-template-columns: 1fr;

    // Force the full width of the container for the animation
    width: 100%;
    min-width: 100%;
    overflow: hidden;
    transform-origin: center center;
    transition: transform $transition-time, opacity $transition-time;
    transform: translateX(0);
    opacity: 0;
    height: 0;

    @for $level from 1 through 2 {
      &--level-#{$level} {
        transform: translateX(-100% * $level);
      }
    }

    &--active {
      opacity: 1;
      height: 100%;
      transition: transform $transition-time, opacity $transition-time, height 0s;
    }

    .kr-grid-group {
      display: grid;
    }

    @include option.main;
  }

  @include option-btn;
}

@mixin option-btn() {
  .kr-method-btn {
    position: relative;

    @include option.icon;
    @include option-label;
    @include back-button.main;
  }
}

@mixin option-label() {
  &__label {
    flex-grow: 1;
    cursor: pointer;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;

    &--hidden {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    label {
      display: inline-flex;
      user-select: none;
      cursor: pointer;
    }
  }
}
