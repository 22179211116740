// CVV Visibility
.kr-visibility-button {
  padding-right: 10px;
}

.kr-field-element .kr-field-wrapper .kr-visibility-button {
  display: flex !important;
  transition: opacity 0.3s;

  // Not visible
  &:not(.kr-visible) {
    opacity: 0;
  }

  // Visible
  &.kr-visible {
    opacity: 1;
  }

  svg path {
    transition: fill 0.2s;
  }
}
