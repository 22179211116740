@use "../variables" as var;

@mixin main() {
  .kr-popin-modal-footer {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: var.$footer-height;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;

    img {
      margin-right: 30px;
      height: 40px;
    }

    img.kr-logo-payzen {
      height: 40px;
    }

    img.kr-logo-lyra {
      height: 40px;
    }

    img.kr-logo-sogecom {
      height: 20px;
    }

    img.kr-logo-cdn {
      height: 30px;
    }
  }
}
