.kr-field-element.kr-disabled.kr-pan,
.kr-field-element.kr-disabled.kr-field.kr-text,
.kr-field-element.kr-disabled.kr-field.kr-checkbox,
.kr-field-element.kr-disabled.kr-expiry,
.kr-field-element.kr-disabled.kr-security-code,
.kr-field-element.kr-disabled.kr-identity-document-number,
.kr-field-element.kr-disabled.kr-card-holder-name,
.kr-field-element.kr-disabled.kr-card-holder-mail,
.kr-field-element.kr-disabled.kr-identity-document-type,
.kr-field-element.kr-disabled.kr-installment-number,
.kr-field-element.kr-disabled.kr-first-installment-delay {
  background-color: $color-disabled;

  .kr-field-component,
  .kr-selected-option,
  .kr-field-wrapper.kr-text-wrapper.kr-disabled .kr-field-component input.kr-input-field,
  .kr-field-wrapper .kr-help-button-wrapper.kr-inner .kr-help-button .kr-help-icon-wrapper .kr-help-icon {
    background-color: $color-disabled;
  }
}

.kr-outer-wrapper.kr-disabled,
.kr-inner-wrapper.kr-disabled,
.kr-inner-wrapper.kr-disabled input.kr-input-field.kr-disabled {
  background-color: $color-disabled;
}

&.kr-ios .kr-inner-wrapper.kr-disabled input.kr-input-field,
&.kr-safari .kr-inner-wrapper.kr-disabled input.kr-input-field {
  color: #333;
}
