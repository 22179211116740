.kr-dialog-content {
  display: none;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  &--active {
    display: block;
  }
}
