.kr-field-element.kr-focus:not(.kr-do-register) {
  border: 1px solid $color-primary-light;
}

.kr-do-register.kr-focus .kr-field.kr-checkbox.kr-visible.kr-checkbox-type-switch {
  .kr-field-wrapper .kr-checkbox-input-wrapper .kr-field-control.kr-checkbox {
    .kr-checkbox-layout .kr-checkbox-control-indicator,
    .kr-checkbox-control-indicator {
      &::before {
        border: 1px solid var(--kr-form-checkbox-focus-borderColor);
      }
    }

    .kr-checkbox-layout .kr-label label.kr-checkbox-label {
      color: #777;
    }
  }
}
