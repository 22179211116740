@use "./header";
@use "./wallet";
@use "./cards";
@use "./opm";
@use "./modal";

@mixin main() {
  display: grid;
  grid-template-columns: 1fr;
  transition: width 0.3s;

  @include header.section;
  @include wallet.section;
  @include cards.section;
  @include opm.section;
  @include modal.main;
}
