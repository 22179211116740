$bg-color: #ededed;

.kr-skeleton-wrapper {
  // Header
  .kr-skeleton-header {
    display: flex;
    width: 100%;
    height: $smartform-header-height;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  // Labels
  .kr-skeleton-method-label {
    display: flex;
    width: 100%;
    height: $smartform-option-height;
    border-radius: 4px;
    margin: 10px 0;
  }
}
