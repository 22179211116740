@mixin main() {
  .kr-loading-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.25s ease-out;
    opacity: 0;
    cursor: pointer;
    z-index: 9999;

    &.kr-loading-overlay--visible {
      opacity: 1;

      .kr-overlay-background {
        opacity: 0.7;
      }
    }

    .kr-overlay-background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #232323;
      opacity: 0;
      transition: opacity 0.2s ease-out;
    }

    .kr-overlay-logo {
      z-index: 1;
      cursor: pointer;
    }

    .kr-overlay-message {
      color: white;
      display: block;
      width: 100%;
      text-align: center;
      position: relative;
      cursor: pointer;
      top: 50%;
      bottom: 50%;
    }
  }
}
