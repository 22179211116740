@use "../../variables" as *;
@use "../../popin/header";
@use "../../popin/footer";
@use "../../popin/smartform/back-button";
@use "../../fonts";
@use "../../scroll";

$spacing: 15px;
$view-padding: 0 $spacing;

@mixin wrapper() {
  .kr-extras-form {
    @include modal;
  }
}

@mixin modal() {
  .kr-extras-form-modal {
    min-width: $smartform-popin-width;
    padding: $smartform-grid-popin-padding;
    background-color: transparent;

    @include header.smartform;
    @include label(
      $padding: $spacing + 5px $spacing,
      $box-sizing: content-box
    );
    @include content;
    @include error;
    @include footer.smartform;
  }
}

@mixin label($padding, $box-sizing) {
  .kr-extras-form-header {
    @include fonts.default;

    box-sizing: $box-sizing;
    padding: $padding;
    color: $color-primary;
  }
}

@mixin content() {
  .kr-extras-form-content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    background-color: $color-light;
    padding: $view-padding;
    gap: $card-elements-gap;

    .kr-field-element:not(.kr-checkbox, .kr-radio),
    .kr-field-element .kr-field,
    .kr-payment-button {
      margin: 0;
    }

    .kr-form-error {
      max-width: 290px;
    }
  }
}

@mixin content-embedded() {
  .kr-extras-form {
    @include label(
      $padding: 0 0 $spacing,
      $box-sizing: border-box
    );
    @include content;
  }
}

@mixin error() {
  &__error {
    padding: $spacing $spacing 0 $spacing;

    .kr-form-error {
      padding: 0;
    }
  }
}
