@use "sass:color";
@use "../../../../mixins/smartform/button";
@use "../../../../mixins/smartform/radio-button";
@use "../../variables" as *;
@use "./common";

@mixin list-button() {
  @include common.list-button;

  .kr-methods-group-activator,
  .kr-method-label {
    width: 100%;
    transition: 0.3s all;

    &.kr-locked {
      .kr-method-label-wrapper .kr-card-icons {
        cursor: not-allowed;
      }
    }

    .kr-method-icon {
      align-items: center;
    }

    .kr-loading-animation {
      position: relative;
      margin-left: auto;
    }

    .kr-method-label-wrapper {
      label {
        margin-right: 0;
      }
    }
  }

  .kr-method-label {
    .kr-method-icon {
      height: 100%;
    }
  }

  .kr-field-element--last-visible {
    margin-bottom: 0;
  }
}

@mixin group() {
  @include common.group;

  .kr-methods-group {
    .kr-methods-group-activator {
      padding: 0 15px 0 18px;

      .kr-extra-brands {
        max-width: 16px;
      }

      label:last-child {
        margin-left: auto;
        text-align: end;
      }
    }
  }
}

// .kr-methods-list-options
@mixin single-payment-button() {
  @include radio-button.main(
    $size: $smartform-radio-button-size,
    $border-color: #e3e3e3,
    $border-color-hover: $color-primary,
    $active-color: $color-primary
  );

  .kr-smart-form-radio-button {
    padding-right: 15px;
  }

  .kr-method-label {
    &.kr-method-label--spbtn {
      box-shadow: none;
      border-color: #e3e3e3;
    }

    &.kr-method-label--selected {
      background-color: var(--kr-global-color-primaryLight);
      border-color: $color-primary;
    }

    &.kr-method-label--warning {
      @include button.warning-button-animation;
      @include radio-button.warning-animation;
    }
  }

  .kr-methods-group {
    &.kr-methods-group-activator--spbtn {
      box-shadow: none;
      border-color: #e3e3e3;
    }

    &.kr-methods-group-activator--selected {
      background-color: var(--kr-global-color-primaryLight);
      border-color: $color-primary;
    }

    &.kr-methods-group-activator--warning {
      @include button.warning-button-animation;
      @include radio-button.warning-animation;
    }
  }
}
