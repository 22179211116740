@use "./button";

$animation-name: "check-button";
$svg-width: 12px;
$svg-height: 9px;

@mixin keyframes() {
  @keyframes #{animation} {
    50% {
      transform: scale(1.2);
    }
  }
}

@mixin main(
  $size: 24px,
  $border-color: #b9b8c3,
  $border-color-hover: #3c53c7,
  $active-color: #3c53c7,
  $flash-color: #506eec,
  $checkmark-color: white
) {
  .kr-smart-form-radio-button {
    cursor: pointer;

    span {
      cursor: pointer;
      display: inline-block;
      position: relative;
      width: $size;
      height: $size;
      border-radius: 50%;
      transform: scale(1);
      vertical-align: middle;
      border: 1px solid $border-color;
      transition: all 0.2s ease;
      background-color: white;

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        background: $flash-color;
        display: block;
        transform: scale(0);
        opacity: 1;
        border-radius: 50%;
        transition-delay: 0.2s;
      }

      svg {
        position: absolute;
        z-index: 1;
        top: calc(($size - $svg-height) / 2);
        left: calc(($size - $svg-width) / 2);
        fill: none;
        stroke: $checkmark-color;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
      }
    }

    &.kr-smart-form-radio-button--hover {
      span {
        border-color: $border-color-hover;
      }
    }

    &.kr-smart-form-radio-button--checked {
      span {
        border-color: $active-color;
        background: $active-color;
        animation: #{$animation-name} 0.6s ease;
        color: #b9b8c3;
        transition: all 0.3s ease;

        &::before {
          transform: scale(2.2);
          opacity: 0;
          transition: all 0.6s ease;
        }

        svg {
          stroke-dashoffset: 0;
        }
      }
    }
  }
}

@mixin warning-animation() {
  .kr-smart-form-radio-button {
    span {
      @include button.warning-border-animation;
    }
  }
}

@mixin absolute() {
  .kr-smart-form-radio-button {
    position: absolute;
    z-index: 1;
    left: 16px;
    padding: 0;
  }
}
