@use "./smartform/card-icons";
@use "./variables" as var;

$margin: 15px;

@mixin -title {
  .kr-simple-modal-header-title {
    font-size: 20px;
    margin: 0;

    span {
      font-size: inherit;
    }
  }
}

@mixin main() {
  .kr-simple-modal-wrapper {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    .kr-simple-modal {
      max-width: 400px;
      width: 100%;
      background-color: #fff;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
      pointer-events: all;

      .kr-simple-modal-header {
        display: flex;
        align-items: center;
        gap: 12px;

        @include -title;

        i.fi-x-circle.icon-close-popup {
          box-sizing: border-box;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 10px;
          right: 10px;
          height: 18px;
          width: 18px;
          cursor: pointer;
        }

        @include card-icons.brands($container-element: none);
      }

      .kr-simple-modal-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;

        .kr-simple-modal-content-main {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .kr-simple-modal-content-optional {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .kr-simple-modal-content-optional-item {
            display: flex;
            gap: 5px;

            a {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }

      .kr-simple-modal-footer {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        width: 100%;
        padding-top: 10px;
        box-sizing: border-box;

        button {
          margin: 0;
          cursor: pointer;
          text-align: center;
          text-transform: uppercase;
        }

        &--vertical {
          flex-direction: column;
          gap: 2px;
          padding-top: 0;
          align-items: center;

          button {
            width: fit-content;
          }
        }
      }
    }
  }
}
