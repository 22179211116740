@use "./method-option" as option;

@mixin section() {
  .kr-sf-wallet {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    &__methods {
      display: grid;
      grid-template-columns: 1fr;

      @include group;
    }
  }
}

@mixin group() {
  .kr-wallet-group {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%; // Force the full width of the container for the animation

    @include option.main;
    @include option-btn;
  }

  .kr-wallet-group--compact {
    $columns: 2;
    $gap: 10px;

    display: grid;
    grid-template-columns: repeat($columns, calc(50% - ($gap / 2)));
    gap: $gap;

    .kr-method-btn {
      margin: 0;

      &:last-of-type:nth-of-type(odd) {
        grid-column: 1 / span 2;
      }
    }
  }
}

@mixin option-btn() {
  .kr-method-btn {
    @include option.icon;
    @include option-label;
    @include option-delete-btn;
  }
}

@mixin option-label() {
  &__label {
    flex-grow: 1;
    cursor: pointer;
  }

  &__label span {
    cursor: pointer;
  }

  &:not(.kr-method-btn--cards) {
    .kr-method-btn__label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@mixin option-delete-btn() {
  .kr-delete {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: auto;
      height: 10px;
    }
  }
}
