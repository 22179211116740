.kr-extras-form {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  span.kr-extras-form-header {
    box-sizing: border-box;
    display: inline-block;
    width: 300px;
    background-color: #fff;
  }

  .kr-extras-form-content {
    display: block;
    background-color: #fff;
    box-sizing: border-box;
  }
}
