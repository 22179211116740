@use "sass:color";
@use "../radio-button";
@use "../../../package/no-theme/smartform/loading-animation";

$radio-btn-border: 1px;

@mixin radio-btn(
  $size,
  $border-color,
  $border-color-hover,
  $active-color,
  $margin
) {
  @include radio-button.main(
    $size: $size,
    $border-color: $border-color,
    $border-color-hover: $border-color-hover,
    $active-color: $active-color
  );

  .kr-smart-form-radio-button {
    display: flex;
    justify-content: flex-end;
    min-width: $size + $margin + ($radio-btn-border * 2);
  }
}

@mixin loading-animation($color-primary) {
  .kr-loading-animation {
    position: relative;
    display: block;
    width: 50px;
    height: 100%;

    @include loading-animation.main($color-primary);

    i.kr-loader::before,
    i.kr-loader::after {
      border-radius: 2px;
    }
  }
}

@mixin hover($border-color) {
  &--hover {
    border-color: $border-color;
  }
}

@mixin disabled($border-color, $font-color) {
  &--disabled {
    border: 1px solid $border-color;
    cursor: not-allowed;

    label,
    span {
      color: $font-color;
      cursor: not-allowed;
    }
  }
}

@mixin no-label() {
  &--no-label {
    &.kr-method-btn--spbtn {
      &:not(.kr-method-btn--small) {
        .kr-smart-form-radio-button {
          position: absolute;
          left: 0;
        }
      }
    }

    .kr-icon {
      flex-grow: 1;

      .kr-brand-icon,
      .kr-brand-icon svg {
        height: 22px;
        width: 42px;
        padding: 0;
        margin: 0;
      }
    }
  }
}
