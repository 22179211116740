@use "../../mixins/smartform/card-tooltip";
@use "./smartform/card-icons";
@use "./modal";

@mixin smartform() {
  .kr-layer-manager {
    @include card-icons.brands;
    @include card-tooltip.main($padding: 6px, $border-radius: 5px);
  }
}

@mixin main() {
  .kr-layer-manager {
    @include modal.main;
  }
}
