@use "sass:math";
@use "../scroll";

// Custom select (desktop)
.kr-field-wrapper.kr-select-wrapper.kr-custom-select {
  // Field
  .kr-select {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none;
    width: 100%;
    height: $field-height;
    padding-left: 14px;
    cursor: pointer;

    span.kr-selected-option {
      font-family: Roboto, sans-serif;
      color: $color-input-font;
      font-size: 13px;
      width: calc(100% - 25px);
      cursor: pointer;
    }

    span.kr-selected-option.kr-placeholder {
      color: $color-input-placeholder;
    }

    // Arrow
    .kr-select-caret {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      right: 13px;
      top: 0;
      height: $field-height - 1px;
      cursor: pointer;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: $color-input-font;
        }
      }

      &.kr-read-only svg {
        fill: $color-input-placeholder;
      }
    }
  }

  // Options
  .kr-options {
    width: calc(100% - 2px);
    left: 0;
    max-height: $field-height * 6;
    overflow-x: hidden;
    overflow-y: auto;
    border-left: 1px solid $color-grey-border;
    border-right: 1px solid $color-grey-border;
    border-bottom: 1px solid $color-grey-border;
    border-radius: $border-radius;
    z-index: 10000;
    cursor: pointer;

    @include scroll.main;

    .kr-option {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: $field-height;
      padding-left: 14px;
      font-family: Roboto, sans-serif;
      color: $color-input-font;
      font-size: 13px;

      &:hover {
        background-color: $color-hover;
      }
    }

    // Position depending of the options
    &.top {
      &.kr-options-6 {
        top: -$field-height * 6;
      }

      &.kr-options-5 {
        top: -$field-height * 5;
      }

      &.kr-options-4 {
        top: -$field-height * 4;
      }

      &.kr-options-3 {
        top: -$field-height * 3;
      }

      &.kr-options-2 {
        top: -$field-height * 2;
      }

      &.kr-options-1 {
        top: -$field-height * 1;
      }
    }

    &.bottom {
      top: $field-height - 1px;
    }

    &:not(:hover) {
      .kr-option.kr-active-option {
        background-color: $color-hover;
      }
    }
  }
}

// Default select (mobile)
.kr-field-wrapper.kr-select-wrapper.kr-real-select {
  position: relative;

  select {
    box-sizing: border-box;
    border: none;
    line-height: $field-height - 7px;
    padding-left: 11px;
    font-family: Roboto, sans-serif;
    color: $color-input-font;
    font-size: 13px;
    margin: 3px;
  }

  span.kr-select-caret {
    position: absolute;
    right: 13px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    svg,
    &.kr-read-only svg {
      width: 18px;
      height: 18px;
      fill: $color-input-font;
    }
  }

  span.kr-select-placeholder {
    position: absolute;
    left: 14px;
    top: 3px;
    height: calc(100% - 6px);
    right: 40px;
    line-height: $field-height - 8px;
    font-family: Roboto, sans-serif;
    color: $color-input-font;
    font-size: 13px;
    pointer-events: none;
  }
}
