/* Help */
.kr-field-wrapper .kr-help-button-wrapper {
  display: none;
}

.kr-field-wrapper .kr-help-button-wrapper.kr-inner {
  @include order(4);

  display: none;

  &.kr-visible {
    display: block;

    .kr-help-button {
      display: none;
    }
  }
}
