$icon-width: 19px;
$icon-height: 15px;

.kr-pan .kr-icon-wrapper-root {
  .kr-brand-selector {
    transition: opacity 0.3s;
  }

  // Closed
  &:not(.kr-open) .kr-brand-selector {
    opacity: 0;

    .kr-brand-selector-wrapper ul.kr-brand-selector-list li,
    svg {
      cursor: default;
      pointer-events: none;
    }
  }

  // Open
  &.kr-open .kr-brand-selector {
    opacity: 1;
    z-index: 10;
  }

  .kr-brand-selector {
    position: absolute;
    right: 19px;
    top: 1px;
    border-radius: 4px;
    background-color: #fff;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgb(0, 0, 0, 25%) -8px 7px 30px -7px;
    padding: 3px;
    border: 1px solid #ddd;

    .kr-brand-selector-wrapper {
      position: relative;
    }

    // List
    .kr-brand-selector-wrapper ul.kr-brand-selector-list {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
    }

    // Items of te list
    .kr-brand-selector-wrapper ul.kr-brand-selector-list li {
      background-color: #fff;
      padding: 4px;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;

      svg {
        padding: 0 3px;
        opacity: 0.7;
        width: $icon-width + 10px;
        height: $icon-height + 6px;
        border-radius: 3px;
        box-shadow:
          rgb(0, 0, 0, 7%) 0 6px 24px 0,
          rgb(0, 0, 0, 40%) 0 0 0 1px;
        transition: all 0.2s;
        box-sizing: border-box;
        cursor: pointer;
      }

      &.kr-selector-cb svg {
        padding: 0;
        height: $icon-height + 4px;
      }

      &.kr-selector-visa svg {
        padding: 0 1px;
      }

      &.kr-selector-visa_electron svg,
      &.kr-selector-visa_debit svg {
        padding: 0;
      }

      &.kr-selector-amex svg {
        background-color: #006fce;
      }

      &.kr-selector-kadeos_culture,
      &.kr-selector-kadeos_gift {
        svg {
          height: $icon-height + 4px;
        }
      }

      &.prv_opt {
        .kr-icon-inner-wrapper .kr-icon-wrapper-content svg {
          height: $icon-height + 4px;
        }
      }

      // Put the brand selected last
      &.kr-selected-brand {
        order: 1;
      }

      &:not(.kr-selected-brand) {
        order: 2;
      }

      &.kr-selected-brand svg {
        cursor: default;
      }

      &.kr-selected-brand svg,
      &:hover svg {
        opacity: 1;
        box-shadow:
          rgb(0, 0, 0, 7%) 0 6px 24px 0,
          rgb(0, 0, 0, 40%) 0 0 0 1px;
      }
    }
  }
}
