@mixin header($font-family: $font-family) {
  .kr-sp-header-label {
    --kr-sp-font-family: var(--kr-white-label-sp-font-family, #{$font-family});
    --kr-sp-amount-color: var(--kr-white-label-header-amount-color, #130e37);
    --kr-sp-total-color: #595d62;

    user-select: none;
    display: block;
    color: var(--kr-sp-total-color);
    font-family: var(--kr-sp-font-family);
    font-size: 14px;
    font-weight: 400;

    .kr-sp-header-amount {
      color: var(--kr-sp-amount-color);
      font-family: inherit;
      font-size: 18px;
      font-weight: 500;
    }

    .kr-sp-header-total {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
    }

    @content;
  }
}

@mixin button($font-family: $font-family) {
  .kr-sp-button-label {
    --kr-sp-font-family: var(--kr-white-label-sp-font-family, #{$font-family});
    --kr-sp-total-color: #ddd;

    .kr-sp-button-amount {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
    }

    .kr-sp-button-total {
      color: var(--kr-sp-total-color);
      font-family: inherit;
      font-size: 12px;
      font-weight: 400;
    }

    @content;
  }
}
