@mixin main() {
  $icon-width: 30px;
  $icon-height: 20px;

  .kr-method-icon {
    display: none;
    width: $icon-width;
    height: $icon-height;
    margin-right: 15px;
    cursor: pointer;

    svg,
    img {
      width: $icon-width;
      height: $icon-height;
    }
  }
}

@mixin visible() {
  .kr-method-icon {
    display: inline-flex;
  }
}
