@import "../../base/custom_radio";

// Radio
.kr-field.kr-radio {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: $field-height;

  // Label
  label {
    outline: none;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    color: $color-input-font;
  }

  // Radio element
  .kr-radio-control-indicator {
    border: 1px solid $color-grey-icons;
  }

  input[type="radio"]:checked + .kr-radio-control-indicator .kr-checked {
    background-color: $color-grey-icons;
  }
}
