@use "../discount-panel";
@use "../wallet";
@use "./currency-conversion";
@use "./button";
@use "./../smartform/card-header";
@import "./help_modal";

.kr-embedded {
  width: $form-width;

  @import "./field_icon";
  @import "./brand_icon";
  @import "./brand_selector";
  @import "./help_icon";
  @import "./visibility_icon";
  @import "./field";
  @import "./field_state";
  @import "./disabled";
  @import "./select";
  @import "./../error";
  @import "./checkbox";
  @import "./radio";
  @include currency-conversion.main;

  // Wallet
  @include wallet.delete-button-main;
  @include wallet.card-list-embedded;
  @include wallet.tabs-embedded;

  // Card Header
  @include card-header.main;

  // Discount
  @include discount-panel.embedded;

  /* Popin */
  &[kr-popin] {
    @import "./../popin/index";
  }

  @include button.main;
}

.kr-smart-form {
  @include button.main;
}
