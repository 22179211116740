@mixin embedded() {
  .kr-wallet-tabs {
    width: 100%;
    display: flex;
    cursor: pointer;

    .kr-wallet-tab {
      flex-grow: 1;
      display: flex;
      align-items: center;
      flex-direction: column;

      &.kr-selected {
        background-color: grey;
      }
    }
  }
}
