$bg-color: #ededed;

@mixin animation() {
  @keyframes skeleton-animation {
    to {
      background-position-x: -20%;
    }
  }
}

.kr-skeleton-wrapper {
  @include animation;

  /* Background Animation */
  .kr-skeleton {
    box-sizing: border-box;
    border: 1px solid #ddd;
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0%) 40%,
        rgba(255, 255, 255, 70%) 50%,
        rgba(255, 255, 255, 0%) 60%
      )
      $bg-color;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s skeleton-animation ease-in-out infinite;
    box-shadow: rgba(149, 157, 165, 15%) 0 0 24px;
  }
}
