@use "sass:color";

$animation-name: "flash-color-field";
$before-animation-name: "#{$animation-name}-before";
$before-border-radius: 0 !default;

@mixin flash-color-keyframes($base-color) {
  @keyframes #{$animation-name} {
    from {
      border-color: $base-color;
    }

    to {
      border-color: color.scale($base-color, $lightness: 50%);
    }
  }

  @keyframes #{$before-animation-name} {
    from {
      background-color: color.adjust($base-color, $alpha: -0.9);
    }

    to {
      background-color: color.adjust($base-color, $alpha: -0.98%);
    }
  }
}

@mixin warning-animation() {
  animation: 0.8s ease-out 0s #{$animation-name};

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: $before-border-radius;
    animation: 0.8s ease-out 0s #{$before-animation-name};
  }
}
