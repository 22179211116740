@use "../../variables" as var;
@use "../../popin/header";
@use "../../popin/footer";
@use "../modal_back_button" as back-button;

$spacing: 15px;
$cards-view-padding: $spacing 0 0 0;
$resize-transition-time: 0.25s;

@mixin main() {
  .kr-sf-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-height: 800px) {
      padding: 20px 0;
      justify-content: flex-start;
    }

    @include modal;
  }

  .kr-sf-wrapper .kr-sf-modal-wrapper .kr-modal.kr-modal {
    @include mobile;
  }
}

@mixin modal() {
  .kr-modal {
    position: relative;
    min-width: var.$modal-width;
    padding-bottom: var.$footer-height;
    padding-top: var.$header-height + var.$modal-content-top-margin;
    background-color: #fff;
    box-shadow:
      rgb(0, 0, 0, 30%) 0 19px 38px,
      rgb(0, 0, 0, 22%) 0 15px 12px;
    opacity: 0;
    transform: scale(0.7);
    transition: all $resize-transition-time ease-out;
    transition-property: opacity, transform;

    &--open {
      opacity: 1;
      transform: scale(1);
    }

    &--iframe-visible {
      padding: 30px 0 0;
      z-index: 10000;
    }

    @include header.smartform;
    @include content;
    @include error;
    @include footer.main;

    // Variants
    @include iframe;
  }
}

@mixin content() {
  .kr-modal-content {
    display: flex;
    overflow: hidden;
    transition: all $resize-transition-time ease-out;
    width: var.$modal-width;

    @include view;
    @include redirection;
  }
}

@mixin content-mobile() {
  .kr-modal-content {
    width: 100%;
    height: 100%;
    min-height: unset;

    @include view-mobile;
  }
}

$transition-time: 0.5s;

@mixin view() {
  &__view {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 100%;
    overflow: auto;
    transform-origin: center center;
    transition: transform $transition-time, opacity $transition-time;
    transform: translateX(0);
    opacity: 0;
    height: 0;

    @for $level from 1 through 6 {
      &--level-#{$level} {
        transform: translateX(-100% * $level);
      }
    }

    &--active {
      opacity: 1;
      height: 100%;
    }

    @include view-label;
    @include view-content;
    @include back-button.popin;
  }
}

@mixin view-mobile() {
  &__view {
    height: 100%;
  }
}

@mixin view-label() {
  .kr-modal-view-label {
    width: 100%;
    box-sizing: border-box;
  }
}

@mixin view-content() {
  .kr-modal-view-content {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: flex-start;
    align-items: center;
    justify-items: center;
    width: 100%;

    &--no-button {
      padding: $cards-view-padding;
    }
  }
}

@mixin redirection() {
  &__redirection {
    z-index: 10;
  }
}

@mixin mobile() {
  &--mobile {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @include content-mobile;
  }
}

@mixin iframe() {
  &--iframe-visible {
    .kr-modal-content__level {
      width: 0;
    }
  }
}

@mixin error() {
  &__error {
    box-sizing: border-box;
    display: flex;
    background-color: var.$color-light;
    width: 100%;
  }
}
