@use "../../variables" as *;
@use "../card-header";
@use "./section";

@mixin section() {
  @include card-header.main;

  .kr-sf-header {
    @include section.spacing(
      $gap: $smartform-methods-gap
    );
    @include section.label;
  }

  .kr-card-header {
    margin-bottom: 0;
  }
}
