@use "../wallet";
@use "./header";
@use "./footer";

// To avoid flickering - hide until it's ready
.kr-embedded[kr-popin]:not([kr-form-ready]) {
  display: none;
}

.kr-embedded[kr-popin][krvue] {
  width: $smartform-popin-width;
  background-color: transparent;
  padding-bottom: 70px;
  padding-top: 109px;
  box-shadow:
    rgb(0, 0, 0, 30%) 0 19px 38px,
    rgb(0, 0, 0, 22%) 0 15px 12px;

  @include header.main;
  @include header.low-width;
  @include wallet.card-list-popin;
  @include wallet.tabs-popin;
  @include footer.legacy;

  // Inner wrapper
  .kr-inner-popin-wrapper {
    position: relative;
    display: block;
    background-color: $color-light;
    padding: 20px 15px 0;

    &.kr-wallet.kr-wallet-mycards {
      padding-top: 80px;
    }

    &.kr-wallet.kr-wallet-newcard {
      padding-top: 65px;
    }
  }

  &.kr-secure-iframe-open {
    border: 5px solid $color-light-secondary;
    border-radius: 5px;
    padding-top: 0;

    .kr-inner-popin-wrapper {
      &,
      &.kr-wallet.kr-wallet-mycards,
      &.kr-wallet.kr-wallet-newcard {
        padding: 0;
      }
    }
  }
}

.kr-smart-form-modal-wrapper {
  .kr-smart-form-modal {
    @import "../../../mixins/smartform/modal";
    @include modal-container($smartform-popin-width);
  }
}
