@mixin main() {
  .kr-method-btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 0; // Flex items won't shrink below their content width
    cursor: pointer;

    &--no-label {
      justify-content: center;

      &.kr-method-btn--loading .kr-icon {
        display: none;
      }
    }
  }
}

@mixin icon() {
  .kr-icon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .kr-brand-icon {
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;

      svg,
      img {
        width: 30px;
        height: 20px;
      }
    }
  }
}
