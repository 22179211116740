@use "../../../mixins/smartform/button";
@use "../variables" as var;
@use "../wallet";
@use "./card-icons";

@mixin card() {
  .kr-smart-form-wallet-card {
    display: flex;

    @include card-icons.brands($container-element: none);
    @include wallet.delete-button-main;

    label {
      span {
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        pointer-events: none;
      }

      .kr-ellipsis {
        letter-spacing: 2px;
      }

      &.kr-wallet-method-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
