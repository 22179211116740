@use "../variables" as var;

@mixin main() {
  .kr-smart-form-modal-button {
    display: inline-block;
    background-color: #eee;
    padding: 5px 10px;
    border: 1px solid #ccc;
    font-size: 15px;
    width: 100%;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

@mixin payment-button() {
  .kr-payment-button {
    background-color: var.$color-primary;
    color: #fff;
  }
}
