@use "../../variables" as *;

@mixin header() {
  .kr-smart-form-embedded-header {
    margin-bottom: $smartform-section-margin;
  }
}

@mixin list() {
  &.kr-methods-list--compact {
    margin-bottom: $smartform-methods-gap;
  }
}

@mixin inner-wrapper() {
  padding: 0 $smartform-popin-margin-x;
}

@mixin section() {
  .kr-smart-form-list-section-name {
    margin-top: $smartform-section-margin;
    margin-bottom: $smartform-section-margin;
  }
}

@mixin wallet($formType) {
  .kr-smart-form-wallet:not(.kr-smart-form-wallet--compact) {
    .kr-smart-form-wallet-card {
      &:not(:last-of-type) {
        margin-bottom: $smartform-methods-gap;
      }

      @if $formType == "popin" {
        margin-top: 12px;
      }
    }
  }

  .kr-smart-form-wallet-error {
    margin-top: $smartform-methods-gap;
    padding-bottom: $smartform-section-margin;
  }
}

@mixin card-form() {
  // Keep in case we find a solution for the extra spacing between the
  // payment button and the "Other payment methods" label
}

@mixin list-options() {
  &.kr-methods-list-options--compact {
    &:not(.kr-methods-list-options--extra) {
      margin-bottom: $smartform-methods-gap;
    }
  }
}

@mixin options-item($formType) {
  .kr-methods-list-options-item {
    margin-bottom: $smartform-methods-gap;
  }

  @if $formType == "popin" {
    .kr-methods-list-options-item {
      margin-top: 12px;
    }

    .kr-methods-list-options--register {
      .kr-smart-form-list-section-name:first-child {
        margin-top: 15px;
      }
    }
  }
}

@mixin partial-payment-panel() {
  .kr-partial-payment-panel {
    margin-top: 10px;
  }
}
