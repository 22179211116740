@mixin main($font-family: system-ui, $color: black) {
  .kr-sp-timeline {
    --kr-sp-timeline-color: var(--kr-sp-color, #{$color});
    --kr-sp-timeline-font-family: var(--kr-sp-font-family, #{$font-family});
    --kr-sp-gray-color: #595d62;

    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 20px;

    section {
      box-sizing: border-box;
    }

    @include -first-payment;
    @include -schedule;
  }
}

@mixin -first-payment() {
  .kr-sp-first-payment {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    min-height: 150px;

    .kr-sp-first-payment-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .kr-sp-first-payment-amount,
      .kr-sp-first-payment-date {
        display: block;
        font-family: var(--kr-sp-timeline-font-family);
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: var(--kr-sp-timeline-color);
      }

      .kr-sp-first-payment-amount {
        font-weight: 600;
        margin-bottom: 5px;
      }

      .kr-sp-first-payment-date {
        font-weight: 400;

        b,
        .kr-sp-first-payment-auxiliary-date {
          @include -font-inherit;
        }

        b {
          font-weight: 500;
        }

        .kr-sp-first-payment-auxiliary-date {
          display: block;
          font-size: 12px;
          color: var(--kr-sp-gray-color);
        }
      }
    }
  }
}

@mixin -schedule() {
  .kr-sp-schedule {
    display: flex;
    max-width: 160px;
    padding: 10px 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;

    @include -dashed-line;

    .kr-sp-schedule-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: stretch;
      gap: 16px;
    }

    .kr-sp-schedule-payment-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
    }

    .kr-sp-schedule-payment-amount,
    .kr-sp-schedule-payment-date {
      display: block;
      color: var(--kr-sp-timeline-color);
      font-feature-settings: "liga" off, "clig" off;
      font-family: var(--kr-sp-timeline-font-family);
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 14px;
      text-align: start;
    }

    .kr-sp-schedule-payment-date {
      b {
        @include -font-inherit;

        font-weight: 500;
      }
    }
  }
}

@mixin -dashed-line() {
  .kr-sp-schedule-dashed-line {
    width: 24px;
  }

  &--1 .kr-sp-schedule-dashed-line {
    height: 52px;
  }

  &--2 .kr-sp-schedule-dashed-line {
    height: 12px;
  }

  &--3 .kr-sp-schedule-dashed-line {
    height: 16px;
  }
}

@mixin -font-inherit() {
  font-feature-settings: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: inherit;
}
