// Field
.kr-field-element.kr-error {
  // Brand Icon
  &.kr-pan .kr-icon .kr-icon-wrapper.default .kr-icon-inner-wrapper .kr-icon-wrapper-content svg path,
  &:not(.kr-pan) .kr-icon .kr-icon-wrapper .kr-icon-inner-wrapper .kr-icon-wrapper-content svg path {
    fill: $color-error;
  }

  // Help icon.kr-field.kr-error
  .kr-field-wrapper .kr-help-button-wrapper.kr-inner.kr-visible .kr-help-button .kr-help-icon-wrapper .kr-help-icon {
    border: 2px solid $color-error;

    svg path {
      fill: $color-error;
    }
  }

  // Visibility button
  .kr-field-wrapper .kr-visibility-button svg path {
    fill: $color-error;
  }
}

// Error message
.kr-form-error {
  display: none;
  padding: 5px 5px 20px;

  &.kr-form-error-visible {
    display: flex;
  }

  &.kr-form-error--warning {
    span:last-child {
      color: $color-warning;
    }

    .kr-extra-content .kr-error-icon {
      background-color: $color-warning;

      svg {
        path {
          fill: $color-warning;
        }
      }
    }
  }

  > span {
    display: flex;
    flex-direction: row;
    align-items: center;

    span:last-child {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: $color-error;
      padding-left: 10px;
    }
  }

  // Error icon
  .kr-extra-content .kr-error-icon {
    width: 28px;
    height: 25px;
    background-color: $color-error;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding-bottom: 3px;
  }

  .kr-extra-content .kr-error-icon svg {
    width: 18px;
    height: 18px;

    path {
      fill: $color-error;
      stroke: #fff;
    }
  }
}
