@use "sass:math";
@use "../variables" as *;

@mixin legacy() {
  .kr-popin-modal-footer {
    border: 5px solid #fff;
    border-bottom-left-radius: $smartform-popin-border-radius;
    border-bottom-right-radius: $smartform-popin-border-radius;
    width: calc(100% - 10px);
    height: 60px;

    .kr-whitelabel-logo {
      img.kr-logo-payzen {
        height: 30px;
      }

      img {
        margin-right: 12px;
      }
    }
  }
}

@mixin smartform() {
  .kr-popin-modal-footer {
    border: $smartform-popin-border-radius solid #fff;
    border-bottom-left-radius: $smartform-popin-border-radius;
    border-bottom-right-radius: $smartform-popin-border-radius;
    width: calc(100% - $smartform-popin-border-radius * 2);
    height: 60px;

    .kr-whitelabel-logo {
      img.kr-logo-payzen {
        height: 30px;
      }

      img {
        margin-right: 12px;
      }
    }
  }
}
