@use "./radio-button";

@mixin main($font-family: system-ui, $color: black, $primary-color: #130E37) {
  .kr-sp-panel {
    --kr-sp-primary-color: var(--kr-global-color-primary, #{$primary-color});
    --kr-sp-circle-color: var(--kr-sp-primary-color);
    --kr-sp-radio-color: #595d62;
    --kr-sp-color: var(--kr-white-label-sp-color, #{$color});
    --kr-sp-font-family: var(--kr-white-label-sp-font-family, #{$font-family});

    display: flex;
    flex-direction: column;
    gap: 0;

    @include -option;
    @include -option-activator;
    @include -option-activator-border-radius;
    @include -option-radio;
  }
}

@mixin -option() {
  .kr-sp-panel-option {
    display: block;
    width: 100%;

    @include -option-content;

    .kr-sp-option-timeline-wrapper {
      display: block;
      padding: 10px 0;
    }

    &:last-child {
      .kr-sp-option-timeline-wrapper {
        padding-bottom: 0;
      }
    }
  }
}

@mixin -option-content() {
  &__content {
    // --kr-sp-opt-content-height is expected to be injected by SplitPanelOption
    --kr-po-height: var(--kr-sp-opt-content-height, 200px);
    --kr-po-transition-duration: 0.4s;
    --kr-po-transition-type: ease-in-out;

    display: block;
    overflow: hidden;
    transition:
      max-height var(--kr-po-transition-duration) var(--kr-po-transition-type),
      opacity var(--kr-po-transition-duration) var(--kr-po-transition-type);
    max-height: 0;
    opacity: 0;

    &--open {
      transition:
        max-height var(--kr-po-transition-duration) var(--kr-po-transition-type),
        opacity var(--kr-po-transition-duration) var(--kr-po-transition-type);
      max-height: var(--kr-po-height);
      opacity: 1;
    }
  }
}

@mixin -option-activator-border-radius() {
  $border-radius: 4px;

  // Selected option, except if there is not timeline displayed (e.g: Pay Now)
  .kr-sp-panel-option--selected .kr-sp-option-activator:last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  // Next option immediately after selected one
  .kr-sp-panel-option--selected + .kr-sp-panel-option .kr-sp-option-activator {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  // First option in the list
  .kr-sp-panel-option:first-child .kr-sp-option-activator {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  // Last option in the list
  .kr-sp-panel-option:last-child .kr-sp-option-activator {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

@mixin -option-activator() {
  .kr-sp-option-activator {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 15px;
    background-color: white;
    border-radius: 0;
    outline: none;
    border: 1px solid #e1e1e1;
    cursor: pointer;
    color: #595d62;
    font-feature-settings: "liga" off, "clig" off;
    font-family: roboto, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;

    &--selected {
      background-color: var(--kr-sp-primary-color);
      border-color: var(--kr-sp-primary-color);
      color: white;
    }

    .kr-sp-option-activator-label {
      @include -font-inherit;

      cursor: inherit;
    }
  }

  .kr-sp-panel-option:not(:last-child, .kr-sp-panel-option--selected) .kr-sp-option-activator {
    border-bottom: none;
  }
}

@mixin -option-radio() {
  @include radio-button.main($size: 16px);
}

@mixin -font-inherit() {
  font-feature-settings: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: inherit;
  color: inherit;
}
