@use "package/no-theme/discount-panel";
@use "package/no-theme/wallet";
@use "package/no-theme/smartform/iframe-container";
@use "package/no-theme/popin-redirection";
@use "package/no-theme/currency-conversion";
@use "./package/no-theme/smartform/card-header";
@import "./mixins/no_select";
@import "./mixins/flex";
@import "./mixins/boxes";
@import "./package/no-theme/variables";
@import "./package/no-theme/form";
@import "./package/no-theme/popin";
@import "./package/no-theme/brand-button";
@include discount-panel.root;

.kr-smart-form {
  max-width: 100%;
  position: relative;
  z-index: 1;

  @import "./package/no-theme/smartform/index";
  @import "./package/no-theme/smartform/smart_button";
  @include iframe-container.main;
}

@import "./package/no-theme/layer_manager";
@import "./package/no-theme/smartform/smart_button";

.kr-embedded {
  position: relative;

  @import "./package/no-theme/icon";
  @import "./package/no-theme/field";
  @import "./package/no-theme/button";
  @import "./package/no-theme/select";
  @import "./package/no-theme/shake";
  @import "./package/no-theme/custom_field";
  @include wallet.delete-button-main;
  @include wallet.card-list-embedded;
  @include wallet.tabs-embedded;
  @import "./package/no-theme/visibility_button";
  @import "./package/no-theme/layer";
  @import "./package/no-theme/error";
  @import "./package/no-theme/help_icon";
  @include card-header.main;
  @include currency-conversion.main;

  /* Payment button CSS */
  button::-moz-focus-inner {
    border: 0;
  }

  /* Reset CSS */
  input.kr-input-field {
    margin: 0;
    border: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    background: none;
    box-shadow: initial;
    transition: initial;
    height: 1.05em;
    width: 230px;
    line-height: 1em;
    font-size: 1em;

    &::placeholder {
      color: #999;
      opacity: 1;
    }
  }

  /* Form */
  #krFieldRepresentation,
  #krFieldRepresentationError,
  #krFieldRepresentationDisabled {
    position: fixed;
    top: -10000px;
    left: -10000px;
  }

  @include discount-panel.embedded;
}

// 3DS Popin
@include popin-redirection.main;

iframe#krcheckout {
  all: initial;
  display: block;
}
