@use "../../../mixins/field" with (
  $before-border-radius: $border-radius
);
@include field.flash-color-keyframes($color-warning);

// Input element
@mixin input() {
  box-sizing: border-box;
  width: 100%;
  height: $field-height - 14px;
  padding-left: 10px;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  color: $color-input-font;
  background-color: $color-light;
  outline: 0;

  &::placeholder {
    color: $color-input-placeholder;
    opacity: 1;
  }
}

// PreRender
.kr-pan,
.kr-field.kr-text,
.kr-field.kr-checkbox,
.kr-expiry,
.kr-security-code,
.kr-identity-document-number,
.kr-card-holder-name,
.kr-card-holder-mail,
.kr-identity-document-type,
.kr-installment-number,
.kr-first-installment-delay,
& > .kr-field-wrapper,
input.kr-theme {
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 100%;
  height: $field-height;
  background-color: $color-light;
  border: 1px solid $color-grey-border;
  border-radius: $border-radius;
  box-shadow: rgb(149, 157, 165, 15%) 0 0 12px;
  margin-bottom: 16px;
}

input.kr-theme {
  box-sizing: border-box;
  padding-left: 14px;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  color: $color-input-font;

  &::placeholder {
    color: $color-input-placeholder;
    opacity: 1;
  }
}

// Initial state (without content)
.kr-field-element:not(.kr-checkbox, .kr-radio),
& > .kr-field-wrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 100%;
  height: $field-height;
  background-color: $color-light;
  border: 1px solid $color-grey-border;
  border-radius: $border-radius;
  margin-bottom: 16px;
  box-shadow: rgb(149, 157, 165, 15%) 0 0 12px;
  transition: border 0.2s ease-in-out;

  // Host fields
  .kr-field-component {
    box-sizing: border-box;
    padding: 6px 4px;
    height: $field-height - 2px;
    border-radius: $border-radius;
    background-color: $color-light;

    input.kr-input-field {
      @include input;
    }
  }
}

// Slave fields + custom field text
.kr-inner-wrapper input.kr-input-field,
.kr-field.kr-text input {
  @include input;
}

// Custom field text
.kr-field.kr-text {
  .kr-input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    input {
      cursor: text;
      padding-left: 14px;
      box-shadow: none;
    }
  }
}

// Compact mod
.kr-field-element.kr-size-half {
  &.kr-expiry,
  &.kr-security-code {
    display: inline-block;
    width: calc(50% - 5px);
  }

  &.kr-expiry {
    margin-right: 10px;
  }
}

// Warning
.kr-field-element {
  &.kr-field-element--warning {
    @include field.warning-animation;
  }
}
