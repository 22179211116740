@use "../layer-manager";
@use "./../modal";
@use "./../loading-overlay";
@use "./wallet";
@use "./methods-list";
@use "./grid";
@use "./card-header";
@use "./header";
@use "./partial-payment-panel";
@use "./split-payment";
@include layer-manager.smartform;

.kr-smart-form {
  width: $smartform-width;

  &[kr-single-payment-button] {
    width: $smartform-spb-width;
  }

  &[kr-grid] {
    width: $smartform-grid-width;
  }

  &.kr-smart-form--compact {
    width: $smartform-compact-width;
  }

  @import "./skeleton";
  @import "./../error";
  @import "./extras_form";
  @import "./../smartbutton/index";
  @include modal.main;
  @include wallet.main;
  @include loading-overlay.main;
  @include header.main;

  .kr-embedded {
    width: 100%;
  }

  .kr-smart-form-wrapper.kr-type-embedded {
    @include card-header.main;
    @include methods-list.embedded;
    @import "./../popin/smartform/modal";

    .kr-embedded {
      width: 100%;
    }
  }

  &[kr-popin] {
    @import "./../popin/smartform/index";
  }

  // SmartButton
  @import "./../smartbutton/index";

  // SmartForm + kr-embedded attribute
  &[kr-embedded] .kr-smart-form-wrapper.kr-type-embedded.kr-several-payment-methods > .kr-embedded {
    margin-bottom: 10px;
  }

  // Grid mode
  &[kr-grid] {
    @include grid.main;
  }

  @include partial-payment-panel.main;
  @include split-payment.smartform;
}

@at-root {
  @include split-payment.root;
}
