@use "../../../mixins/smartform/button";
@use "../../../mixins/smartform/radio-button";
@use "../variables" as *;
@use "../popin/smartform/error";
@use "../scroll";
@use "./methods-list/spacing";
@use "./methods-list/common";
@use "./methods-list/embedded";
@use "./methods-list/popin";

@mixin keyframes() {
  @include button.flash-color-keyframes("warning", $color-warning);
  @include button.flash-color-keyframes("alert", $color-error);
  @include radio-button.keyframes;
}

@mixin embedded() {
  .kr-smart-form-methods {
    // CardIcons
    .kr-card-icons {
      .kr-method-label {
        @include common.font;
      }
    }

    // MethodsListContent
    .kr-methods {
      .kr-method-content .kr-method-label {
        @include common.font;
      }
    }

    // Header
    @include spacing.header;

    // MethodsListLabel
    .kr-methods-list {
      @include spacing.section;
      @include spacing.wallet("embedded");
      @include spacing.list;

      .kr-methods-list-header,
      .kr-smart-form-list-section-name,
      .kr-method-label {
        @include common.font;
      }

      .kr-methods-list-options {
        @include spacing.options-item("embedded");
        @include spacing.list-options;
        @include embedded.list-button;
        @include embedded.group;
        @include embedded.single-payment-button;

        .kr-methods-list-card-form-wrapper {
          display: block;

          @include spacing.card-form;
        }
      }
    }
  }
}

@mixin popin() {
  .kr-smart-form-methods {
    min-height: 374px;
    overflow: visible;
    background-color: #fff;
    display: block;

    // MethodsListContent
    .kr-methods {
      width: $smartform-popin-width;

      .kr-method-content {
        .kr-method-label {
          @include common.font;

          label {
            font-family: Roboto, sans-serif;
            font-size: 14px;
          }
        }

        .kr-embedded {
          width: $form-width;
        }
      }
    }

    // MethodsListLabel
    .kr-methods-list {
      height: 374px;
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: flex-start;

      @include spacing.section;
      @include spacing.wallet("popin");
      @include scroll.main;

      &.kr-group-active {
        .kr-methods-list-header {
          padding-top: 0;
          padding-bottom: 15px;
        }
      }

      &.kr-error:not(.kr-method-active) {
        @include error.main;

        position: relative;
      }

      .kr-methods-list-inner-wrapper {
        overflow: auto;

        @include spacing.partial-payment-panel;
        @include spacing.inner-wrapper;
        @include scroll.main;
      }

      .kr-methods-list-header,
      .kr-smart-form-list-section-name,
      .kr-method-label {
        @include common.font;
      }

      .kr-methods-list-options {
        display: block;

        @include spacing.options-item("popin");
        @include popin.list-button;
        @include popin.group;
      }

      .kr-methods-list-header {
        cursor: default;
        padding: 20px 15px 15px 17px;
        color: $color-primary;
      }
    }
  }
}

/**
  To avoid double scroll bar @since KJS-3826
**/
@mixin popin-one-scroll() {
  &.kr-smart-form-modal--scrollable-content {
    .kr-smart-form-methods {
      min-height: unset;
      max-height: 100%;
      height: 374px;

      // MethodsListLabel
      .kr-methods-list {
        height: 100%;
      }
    }
  }
}
