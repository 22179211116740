@use "./variables" as *;
@use "./smartform/card-icons";
@use "./spinner";

$animation-opening: "kr-modal-opening";

@mixin keyframes() {
  @keyframes #{$animation-opening} {
    from {
      transform: scale(0.7);
      opacity: 0;
    }

    to {
      transform: scale(1);
      opacity: 1;
    }
  }
}

@mixin -title() {
  .kr-simple-modal-header-title {
    font-family: Roboto, sans-serif;
    color: #333;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;

    span {
      font-family: inherit;
      color: inherit;
      font-size: inherit;
      font-style: inherit;
      font-weight: inherit;
    }
  }
}

@mixin main() {
  .kr-simple-modal-wrapper {
    &.kr-simple-modal-wrapper--opening {
      animation: 0.15s linear 0s 1 normal forwards #{$animation-opening};
    }

    &.kr-simple-modal-wrapper--closing {
      animation: 0.15s linear 0s 1 reverse forwards #{$animation-opening};
    }

    .kr-simple-modal {
      max-width: 380px;
      box-shadow:
        rgb(0, 0, 0, 30%) 0 19px 38px,
        rgb(0, 0, 0, 22%) 0 15px 12px;
      border-radius: 5px;
      border: 1px solid #ddd;

      @media (max-width: 420px) {
        margin: 0 10px;
      }

      .kr-simple-modal-header {
        @include card-icons.brands($container-element: none);

        padding: 10px;

        @include -title;

        i.fi-x-circle.icon-close-popup {
          box-sizing: content-box;

          path {
            fill: $color-primary;
          }
        }
      }

      .kr-simple-modal-content {
        .kr-simple-modal-content-main p {
          font-family: Roboto, sans-serif;
          color: #333;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          padding: 6px 10px;

          strong {
            font-family: Roboto, sans-serif;
            color: #333;
            font-size: 14px;
            font-weight: 700;
          }
        }

        .kr-simple-modal-content-optional {
          margin: 10px 10px 0;
          padding: 15px;
          background-color: #f4f4f4;
          border: 1px solid #ddd;
          border-radius: 3px;

          .kr-simple-modal-content-optional-item {
            span,
            a,
            strong {
              font-family: Roboto, sans-serif;
              color: #333;
              font-size: 14px;
            }

            a {
              color: $color-primary;
              font-weight: 500;
            }
          }
        }
      }

      .kr-simple-modal-footer {
        padding-top: 20px;

        button,
        button span {
          display: block;
          color: #fff;
          font-family: Roboto, sans-serif;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          visibility: visible;
          cursor: pointer;

          // @see KJS-4615 - Only the first word should be capitalized
          // The others should be all in lowercase
          text-transform: lowercase;

          &::first-letter {
            text-transform: uppercase;
          }
        }

        button {
          padding: 8px 16px;
          background-color: $color-primary;
          border-radius: 3px;
          position: relative;

          &.kr-btn-error {
            background-color: $color-error;
          }

          &.kr-btn-secondary {
            background-color: transparent;
            color: #333;

            span {
              color: #333;
            }
          }

          // Spinner
          @include spinner.main($size: 6px);

          // Loading state
          &.kr-loading {
            span {
              visibility: hidden;
            }

            .kr-spinner {
              display: block;
            }
          }
        }

        &--vertical {
          flex-direction: column;
          gap: 2px;
          padding-top: 0;
          align-items: center;

          button {
            width: fit-content;
          }
        }
      }
    }
  }
}
