.kr-field-wrapper {
  @include flexbox;
  @include justify-content(flex-start);
  @include align-items(stretch);

  width: 100%;
  height: 100%;

  .kr-field-component {
    width: 100%;

    @include order(1);
  }

  .kr-icon-wrapper-root {
    @include order(3);
  }

  .kr-help-button-wrapper {
    @include order(4);
  }

  .kr-delete-button-wrapper {
    @include order(5);
  }
}
