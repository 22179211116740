@mixin embedded() {
  .kr-wallet-card-list {
    width: 100%;
    display: flex;

    .kr-card-list-wrapper {
      width: 100%;

      .kr-card-element {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        &.kr-deleted {
          height: 0;
          padding: 0;
          opacity: 0;
        }

        .kr-card-label,
        .kr-brand-icon {
          display: flex;
          align-items: center;
        }

        .kr-card-label {
          flex-direction: row;
        }

        .kr-brand-icon {
          flex-direction: column;

          svg {
            width: 30px;
            height: 20px;
          }
        }

        input[type="radio"] {
          background-color: initial;
          cursor: default;
          appearance: radio;
          box-sizing: border-box;
          padding: initial;
          border: initial;
        }

        i.fi-x-circle {
          cursor: pointer;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
