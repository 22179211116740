/** CHECKBOX **/

/** Regular checkbox **/
.kr-field.kr-checkbox {
  background-color: rgb(0, 0, 0, 0%);
  border: none;
  margin-bottom: 16px;
  min-height: 33px;
  padding-right: 0;
  padding-left: 4px;
  width: 230px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.kr-field.kr-checkbox .kr-checkbox-input-wrapper {
  .kr-field-control {
    color: #777;
    display: block;
    line-height: 33px;
    position: relative;
    user-select: none;

    .kr-label {
      padding-bottom: 2px;
      padding-left: 4px;
    }
  }
}

// Custom control
.kr-field.kr-checkbox.kr-checkbox-type-default .kr-checkbox-input-wrapper {
  .kr-field-control {
    font-size: 0.9em;
    padding-left: 20px;

    // Hide default browser input
    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
  }

  .kr-checkbox-control-indicator {
    background-color: #e6e6e6;
    border: 1px solid #b1b1b1;
    height: 20px;
    left: 0;
    position: absolute;
    top: 5px;
    width: 20px;
    cursor: pointer;
  }

  // Hover and focus
  .kr-field-control:hover input ~ .kr-checkbox-control-indicator,
  .kr-field-control input:focus ~ .kr-checkbox-control-indicator {
    background-color: #ccc;
  }

  // Checked
  .kr-field-control input:checked ~ .kr-checkbox-control-indicator,
  .kr-field-control input:checked:focus ~ .kr-checkbox-control-indicator {
    background-color: #717171;
  }

  // Hover when checked
  .kr-field-control:hover input:not([disabled]):checked ~ .kr-checkbox-control-indicator,
  .kr-field-control input:checked:focus ~ .kr-checkbox-control-indicator {
    background-color: #656565;
  }

  // Hide default browser input
  .kr-field-control input:disabled ~ .kr-checkbox-control-indicator {
    background-color: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }

  .kr-checkbox-control-indicator::after {
    content: "";
    display: none; // Hide check
    position: absolute;
  }

  .kr-field-control input:checked ~ .kr-checkbox-control-indicator::after {
    display: block; // Show check
  }

  // Checkbox tick
  .kr-field-control .kr-checkbox-control-indicator::after {
    box-sizing: initial;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    height: 8px;
    left: 8px;
    top: 4px;
    transform: rotate(45deg);
    width: 3px;
  }

  // Disabled tick colour
  .kr-field-control input:disabled ~ .kr-checkbox-control-indicator::after {
    border-color: #7b7b7b;
  }
}

/******************   */

/* Switch checkbox */
.kr-field.kr-checkbox.kr-checkbox-type-switch .kr-checkbox-input-wrapper {
  .kr-field-control.kr-checkbox {
    input.kr-theme {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .kr-checkbox-control-indicator {
      position: relative;
      cursor: pointer;
      width: 32px;
      height: 19px;
      background-color: #ddd;
      transition: 0.4s;
      border-radius: 34px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 2px;
      margin-right: 5px;
      border: none;
      outline: none;
      user-select: none;
      border-color: transparent;
    }

    .kr-checkbox-control-indicator:focus {
      border: none;
      outline: none;
      border-color: transparent;
    }

    .kr-checkbox-control-indicator::before {
      position: absolute;
      content: "";
      height: 15px;
      width: 15px;
      left: 2px;
      bottom: 2px;
      background-color: #fff;
      transition: all 0.4s;
      border-radius: 50%;
    }

    input:checked + .kr-checkbox-control-indicator {
      background-color: #717171;
    }

    input:checked + .kr-checkbox-control-indicator::before {
      transform: translateX(13px);
    }
  }
}
