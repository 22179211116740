// Card icons
@use "../../../mixins/smartform/card-tooltip";
@use "../skeleton";
@use "../variables" as *;

$animation-name: "kr-card-icon-display";
$skeleton-bg: linear-gradient(
  100deg,
  rgba(255, 255, 255, 0%) 30%,
  rgba(255, 255, 255, 70%) 50%,
  rgba(255, 255, 255, 0%) 70%
);

@mixin keyframes() {
  @keyframes #{$animation-name} {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@mixin main($padding-left: 0, $location: button) {
  .kr-card-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-width: 0;
    width: 100%;
    padding-left: $padding-left;
    cursor: pointer;

    label {
      font-family: Roboto, sans-serif;
    }

    @include tooltip;
    @include brands(span, $location);
  }
}

@mixin tooltip() {
  span.kr-extra-brands {
    height: 20px;
    width: 30px;
    cursor: pointer;
    color: #777;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    max-width: 16px;

    @include card-tooltip.main($padding: 6px, $border-radius: 5px);
  }
}

@mixin brands($container-element: span, $location: button, $icon-width: 30px, $icon-height: 20px) {
  @if $container-element == none {
    $container-element: "";
  }

  #{$container-element}.kr-brand-icon {
    width: $icon-width;
    height: $icon-height;

    svg {
      width: $icon-width;
      height: $icon-height;
    }

    @include placeholder;

    &:not(.kr-cards).kr-loading-assets {
      display: flex;
      justify-content: center;
    }
  }

  #{$container-element}.kr-brand-icon.kr-cards {
    margin-right: 6px;
    box-shadow: $icons-box-shadow;
    border-radius: 3px;
    visibility: visible;
    cursor: pointer;

    @include brand-icons;
    @include skeleton.animation;

    &.kr-loading-assets {
      @if $location == button {
        background: $skeleton-bg $color-light-secondary;
      } @else {
        background:
          100deg,
          rgba(244, 244, 244, 0%) 30%,
          rgba(244, 244, 244, 70%) 50%,
          rgba(244, 244, 244, 0%) 70% $color-light;
      }

      animation: 1s skeleton-animation ease-in-out infinite;
      background-size: 200% 100%;
      background-position-x: 180%;
      transition: all 0.3s;

      svg {
        visibility: hidden;
      }
    }

    // VISA & CB
    &.kr-cb,
    &.kr-visa,
    &.kr-visa_electron,
    &.kr-visa_debit {
      padding: 0;
    }

    // Kadeos
    &.kr-kadeos_culture,
    &.kr-kadeos_gift {
      width: 32px;

      image {
        cursor: pointer;
      }
    }
  }
}

@mixin brand-icons() {
  svg {
    box-sizing: border-box;
    padding: 1px 4px;
    border-radius: 3px;
    animation: icon-display 0.2s ease-in;
  }

  // Amex
  &[class*="kr-amex"] svg {
    background-color: #006fce;
  }

  // Cabal
  &[class*="kr-cabal"] svg {
    padding: 0;
  }

  // CB svg
  &.kr-cb svg {
    width: initial;
  }

  // VISA & CB
  &.kr-cb svg {
    padding: 0;
  }

  &.kr-visa svg {
    padding: 0 1px;
  }

  &.kr-visa_electron svg,
  &.kr-visa_debit svg {
    padding: 0;
  }

  // DISCOVER
  &.kr-discover svg path:nth-child(5) {
    fill: #fff;
  }

  // Kadeos
  &.kr-kadeos_culture svg,
  &.kr-kadeos_gift svg {
    width: 32px;

    image {
      cursor: pointer;
    }
  }

  // Naranja
  &.kr-naranja svg {
    padding: 3px;
  }

  // Assets loading
  &.kr-loading-assets svg {
    padding: 0;
  }
}

@mixin placeholder() {
  @include skeleton.animation;

  .kr-placeholder {
    display: inline-flex;
    box-shadow: $icons-box-shadow;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: $skeleton-bg $color-light-secondary;
    animation: 1s skeleton-animation ease-in-out infinite;
    background-size: 200% 100%;
    background-position-x: 180%;
    transition: all 0.3s;
  }
}
