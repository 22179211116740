@mixin all-fields() {
  .kr-pan,
  .kr-expiry,
  .kr-security-code,
  .kr-installment-number,
  .kr-first-installment-delay,
  .kr-identity-document-type,
  .kr-identity-document-number,
  .kr-card-holder-name,
  .kr-card-holder-mail,
  .kr-do-register,
  div[id^="kr-field-app-"] {
    @content;
  }
}

@mixin regular-fields() {
  .kr-installment-number,
  .kr-first-installment-delay,
  .kr-identity-document-type,
  .kr-identity-document-number,
  .kr-card-holder-name,
  .kr-card-holder-mail,
  .kr-do-register {
    @content;
  }
}

@mixin custom-fields() {
  div[id^="kr-field-app-"] {
    @content;
  }
}
