.kr-visibility-button {
  order: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  svg {
    fill: #717171;
    width: 20px;
  }
}
