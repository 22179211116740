@use "sass:math";
@use "../../variables" as *;
@use "./section";
@use "./option";
@use "../card-icons";
@use "../../../../mixins/smartform/grid/method-option";

$option-items-spacing: 15px;

@mixin section() {
  .kr-sf-cards {
    @include section.spacing(
      $gap: $smartform-methods-gap
    );
    @include section.label;
    @include card-option;
    @include card-form;
    @include section.error;
  }
}

@mixin card-option() {
  .kr-method-btn {
    @include option.base($option-height: $smartform-option-height);

    // Inside button elements
    @include method-option.radio-btn(
      $size: $smartform-radio-button-size,
      $border-color: $smartform-spb-radio-border-color,
      $border-color-hover: $color-primary,
      $active-color: $color-primary,
      $margin: $option-items-spacing
    );
    @include card-icons.main(
      $padding-left: $option-items-spacing
    );
    @include method-option.loading-animation($color-primary);

    // Variants
    @include method-option.hover($border-color: $color-primary);
    @include method-option.disabled(
      $border-color: $border-color-disabled,
      $font-color: $font-color-disabled
    );
  }
}

@mixin card-form() {
  .kr-card-form {
    gap: $card-elements-gap;
    column-gap: $smartform-methods-gap;

    .kr-field-element:not(.kr-checkbox, .kr-radio),
    .kr-field-element .kr-field,
    .kr-payment-button {
      margin: 0;
    }

    .kr-field-element .kr-field.kr-checkbox {
      padding-top: math.div($card-elements-gap, 3);
      padding-bottom: math.div($card-elements-gap, 3);
      width: 100%;
      height: auto;
      min-height: auto;
    }
  }
}
