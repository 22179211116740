// Display animation
.kr-field-wrapper .kr-help-button-wrapper.kr-inner {
  transition: all 0.3s;
}

.kr-pan .kr-field-wrapper .kr-help-button-wrapper.kr-inner {
  overflow: hidden;
}

// Not visible
.kr-pan .kr-field-wrapper .kr-help-button-wrapper.kr-inner:not(.kr-visible) {
  width: 0;
  display: flex !important;
  padding-right: 0;
}

// Visible
.kr-pan .kr-field-wrapper .kr-help-button-wrapper.kr-inner.kr-visible {
  padding-right: 11px;
}

.kr-field-wrapper .kr-help-button-wrapper.kr-inner,
.kr-field-wrapper .kr-help-button-wrapper.kr-inner.kr-visible {
  width: 31px;
  padding-right: 7px;

  .kr-help-button {
    width: 22px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .kr-help-icon-wrapper,
    .kr-help-icon-wrapper .kr-help-icon,
    .kr-help-icon-wrapper .kr-custom-help-icon {
      display: flex;
      cursor: pointer;
    }

    .kr-help-icon-wrapper .kr-help-icon {
      width: 14px;
      height: 14px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border: 2px solid $color-grey-icons;
      border-radius: 50%;
      transition: border 0.2s;
    }

    .kr-help-icon-wrapper .kr-help-icon svg {
      width: 8px;
      height: 8px;

      path {
        fill: $color-grey-icons;
        transition: fill 0.2s;
      }
    }

    .kr-help-icon-wrapper .kr-custom-help-icon svg {
      align-items: center;
      width: 22px;
      height: 22px;
    }

    .kr-help-icon-wrapper .kr-help-icon:hover {
      border: 2px solid $color-grey-icons-hover;

      svg path {
        fill: $color-grey-icons-hover;
      }
    }
  }
}
