$color-light: #fff;
$color-primary: var(--kr-global-color-primary);
$color-error: #c62828;

// Popin
$footer-height: 80px;
$header-height: 90px;

// Smartform
$modal-width: 300px;
$modal-content-top-margin: 6px;
