@use "./card-tooltip";

@mixin main() {
  .kr-card-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    min-width: 0;

    @include tooltip;
    @include brands;
  }
}

@mixin tooltip() {
  span.kr-extra-brands {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    color: #777;
    height: 16px;

    @include card-tooltip.main;
  }
}

@mixin brands($container-element: span) {
  @if $container-element == none {
    $container-element: "";
  }
  #{$container-element}.kr-brand-icon {
    display: inline-block;
    margin: 0;
    cursor: default;
    box-sizing: content-box;
    height: 16px;
    width: 20px;
    vertical-align: baseline;

    &.kr-cards {
      background-color: #fff;
    }

    &.kr-hideout {
      position: fixed;
      top: -9999px;
      left: -9999px;
    }

    svg {
      box-sizing: content-box;
      height: 16px;
      width: 20px;
      vertical-align: baseline;
    }
  }
}
