@use "./wallet";
@use "./loading-overlay";
@use "./methods-list";
@use "./modal-button";
@use "./card-header";
@use "./partial-payment-panel";
@use "./grid";
@import "./skeleton";
@import "./modal";
@import "./extras_form";

.kr-smart-form-wrapper.kr-type-embedded {
  @include card-header.main;
  @include methods-list.embedded;
}

.kr-smart-form-wrapper.kr-type-popin {
  z-index: 0;

  @include methods-list.popin;
}

@include wallet.card;
@include loading-overlay.main;
@include modal-button.main;
@include modal-button.payment-button;
@include partial-payment-panel.main;

// To avoid flickering - hide until it's ready see KJS-3670
.kr-embedded:not([krvue], .kr-extras-form-content) {
  display: none;
}

// Grid mode
&[kr-grid] {
  @include grid.main;
}
