@use "./smartform/card-tooltip";
@use "./smartform/card-icons";
@use "./smartform/method-icons";
@use "./modal";

.kr-layer-manager {
  position: relative;
  z-index: 10000;

  @include card-icons.brands;
  @include card-tooltip.main;
  @include method-icons.main;
  @include modal.main;
  @import "./dialog";
  @import "./help_modal";
  @import "./layer";
}
