@use "../variables" as *;

@mixin main() {
  // Delete button for 1 card
  .kr-delete-button-wrapper {
    cursor: pointer;
    display: block;
    position: relative;
    height: 100%;
    padding: 0 10px 0 4px;

    &:not(.kr-visible) {
      display: none;
    }

    .kr-delete-button {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      .kr-delete-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        svg {
          width: auto;
          height: 20px;

          path {
            fill: $color-grey-icons;
          }
        }

        &.kr-error svg path {
          fill: $color-error;
        }

        &:hover svg path {
          fill: $color-grey-icons-hover;
        }
      }
    }
  }
}
