.kr-layer {
  z-index: 2;

  .kr-layer-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #232323;
    opacity: 0.7;
  }
}
