@use "../../../mixins/flex";
@use "../variables" as *;
@use "../fonts";

@mixin main() {
  // Embedded header
  .kr-smart-form-embedded-header {
    background-color: #f4f4f4;
    padding: 9px 15px 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid $color-primary;
    border-left: 1px solid $smartform-border-color;
    border-right: 1px solid $smartform-border-color;
    border-top: 1px solid $smartform-border-color;
    box-shadow: rgb(33, 35, 38, 10%) 0 10px 10px -10px;
    display: block;

    @include amount-display;
    @include partial-payment-header;
  }
}

@mixin amount-display() {
  .kr-amount-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    @include flex.row-gap(3px);

    .kr-amount-label,
    .kr-amount-figure {
      font-family: $font-family;
      user-select: none;
      cursor: default;
    }

    // Label
    .kr-amount-label {
      font-size: fonts.$font-sm;
      color: fonts.$font-lighter;
    }

    // Amount
    .kr-amount-figure {
      color: $color-primary;
      font-size: 17px;
      padding-left: 5px;
    }
  }
}

@mixin partial-payment-header() {
  &.kr-partial-payment-header {
    padding: 10px 12px;

    .kr-amount-wrapper {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .kr-amount-label {
        color: fonts.$font-light;
        font-weight: fonts.$font-thicker;
      }

      .kr-amount-figure {
        font-weight: fonts.$font-thicker;
        font-size: fonts.$font-md;
      }
    }
  }
}
