@use "./card-icons";

@mixin main() {
  .kr-partial-payment-panel {
    display: flex;
    flex-direction: column;
    width: 100%;

    .kr-partial-payments {
      display: grid;
      grid-template-columns: 1fr;

      @include partial-payment;
    }

    .kr-partial-payment-remaining {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .kr-partial-payment-separator {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }
  }
}

@mixin partial-payment() {
  .kr-partial-payment {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include card-icons.brands($container-element: none);

    label {
      flex: 1;
    }

    .kr-partial-payment-remove-icon-container {
      align-self: stretch;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .kr-partial-payment-remove-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}
