@use "sass:color";
@use "sass:list";

$-labels: "warning", "alert";
$animation-button-name: "flash-color-button";
$animation-border-name: "flash-color-border";

@mixin flash-color-keyframes($label, $base-color) {
  @if not list.index($-labels, $label) {
    @error "flash-color-keyframes: Invalid label #{$label}. Expected one of #{$-labels}";
  }

  @keyframes #{$label}-#{$animation-button-name} {
    from {
      background-color: color.scale($base-color, $lightness: 90%);
      border-color: $base-color;
    }

    to {
      background-color: color.scale($base-color, $lightness: 98%);
      border-color: color.scale($base-color, $lightness: 50%);
    }
  }

  @keyframes #{$animation-border-name} {
    from {
      border-color: $base-color;
    }

    to {
      border-color: color.scale($base-color, $lightness: 50%);
    }
  }
}

@mixin warning-button-animation() {
  animation: 0.8s ease-out 0s warning-#{$animation-button-name};
}

@mixin warning-border-animation() {
  animation: 0.8s ease-out 0s warning-#{$animation-border-name};
}

@mixin alert-button-animation() {
  animation: 0.8s ease-out 0s alert-#{$animation-button-name};
}

@mixin alert-border-animation() {
  animation: 0.8s ease-out 0s alert-#{$animation-border-name};
}
