@use "./../no-theme/smartform/loading-animation";
@use "./layer-manager";
@use "./layer";
@use "./modal";
@use "./popin-redirection";
@use "./discount-panel";
@use "./smartform/card-icons";
@use "./smartform/methods-list";
@use "./form/button";
@use "./root-variables";
@include loading-animation.keyframes;
@include layer.keyframes;
@include card-icons.keyframes;
@include modal.keyframes;
@include methods-list.keyframes;
@import "./variables";

/* Discount info */
@include discount-panel.root;

/* External payment button */
@include button.root;

/* Brand buttons */
@import "./brand_button";

/* Skeleton */
@import "./skeleton";

// CardForm
@import "./form/index";
@import "./popin/index";

/* SmartForm */
@import "./smartform/index";

/* SmartButton */
@import "./smartbutton/index";

// 3DS Popin
@include popin-redirection.main;

/* Layer Manager */
@include layer-manager.main;
@include layer.main;
@include root-variables.root
