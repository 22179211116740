@use "../variables" as *;
@use "./lock-icon";

@mixin main() {
  .kr-popin-modal-header {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-light-secondary;
    height: $header-height;
    top: 0;
    left: 0;
    width: calc(100% - 10px);
    border: $smartform-popin-border solid $color-light-secondary;
    border-top-left-radius: $smartform-popin-border-radius;
    border-top-right-radius: $smartform-popin-border-radius;
    color: #333;

    .kr-popin-modal-header-background,
    .kr-popin-modal-header-background-image {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    // Close button
    i.fi-x-circle.icon-close-popup {
      top: 0;
      right: 0;
      margin: 4px 4px 0 0;
    }

    span.kr-popin-header {
      font-size: 12px;
      line-height: 30px;
      margin: 0;
      padding: 0;
      text-align: center;
      display: block;
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      left: 0;
      top: 0;
    }

    span.kr-popin-shop-name,
    span.kr-popin-order-id,
    span.kr-popin-amount {
      display: block;
      z-index: 1;
    }

    // Shop name
    span.kr-popin-shop-name {
      user-select: none;
      position: absolute;
      left: 10px;
      top: 3px;
      padding: 3px 0 7px;
      border-bottom: 1px solid $color-primary;
      margin: 0;

      span {
        font-family: Roboto, sans-serif;
        font-size: 17px;
        color: #4a4a4a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: $shop-name-width;
        display: block;
      }
    }

    // Shop logo
    .kr-popin-modal-header-image {
      .kr-header-border {
        width: 34px;
        height: 34px;
        background-color: $color-primary;
      }

      .kr-header-logo {
        width: 32px;
        height: 32px;
      }
    }

    // Amount + orderID
    span.kr-popin-amount,
    span.kr-popin-order-id {
      user-select: none;
      position: absolute;
      right: unset;
      left: 60px;

      span {
        font-family: Roboto, sans-serif;
        font-size: 12px;
      }
    }

    span.kr-popin-amount {
      bottom: 30px;

      span {
        color: $color-primary;
        font-size: 18px;
      }
    }

    span.kr-popin-order-id {
      bottom: 10px;
    }

    /* Header logo */
    .kr-popin-modal-header-image {
      position: absolute;
      bottom: 10px;
      left: 10px;
      width: 38px;
      height: 38px;
      top: auto;

      .kr-header-logo,
      .kr-header-border-shadow,
      .kr-header-border {
        border-radius: 50%;
        position: absolute;
      }

      .kr-header-border,
      .kr-header-border-shadow {
        display: none;
      }

      .kr-header-logo {
        width: 36px;
        height: 36px;
        margin-top: 1px;
        margin-left: 1px;
        background-color: #808080;
      }
    }

    // Iframe open
    &.kr-secure-iframe-visible {
      height: 30px;
      margin-bottom: 0;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: #f4f4f4;
      border-radius: 0;
      border: 0;
      width: calc(100% - 10px);
      border-bottom: 5px solid $color-light-secondary;
      border-left: 5px solid $color-light-secondary;
      border-right: 5px solid $color-light-secondary;

      i.fi-x-circle.icon-close-popup {
        margin-top: 0;
      }

      @include lock-icon.main;
    }
  }
}

@mixin low-width() {
  @media (max-width: 450px) {
    &.kr-secure-iframe-open .kr-secure-iframe-visible.kr-popin-modal-header {
      box-sizing: border-box;
      height: 32px;
      width: 100%;
    }

    iframe#krcheckout {
      top: 32px !important;
    }
  }
}

@mixin smartform() {
  .kr-popin-modal-header.kr-smart-form-modal-header {
    border: 5px solid #f4f4f4;
    width: calc(100% - 10px);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 100px;

    // Close button
    &:not(.kr-secure-iframe-visible) i.fi-x-circle.icon-close-popup {
      top: 0;
      right: 0;
      margin: 4px 4px 0 0;
    }

    // Shop name
    span.kr-popin-shop-name {
      left: 10px;
      top: 3px;
      padding: 3px 0 7px;
      border-bottom: 1px solid $color-primary;

      span {
        font-family: Roboto, sans-serif;
        font-size: 17px;
        color: #4a4a4a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: $shop-name-width;
        display: block;
      }
    }

    // Shop logo
    .kr-popin-modal-header-image {
      .kr-header-border {
        width: 34px;
        height: 34px;
        background-color: $color-primary;
      }

      .kr-header-logo {
        width: 32px;
        height: 32px;
      }
    }

    // Amount + orderID
    span.kr-popin-amount,
    span.kr-popin-order-id {
      right: none;
      left: 60px;
      display: block;

      span {
        font-family: Roboto, sans-serif;
      }
    }

    span.kr-popin-amount {
      span {
        color: $color-primary;
        font-size: 18px;
      }
    }

    .kr-popin-total-amount {
      bottom: 12px;

      .kr-total-amount-label {
        font-family: Roboto, sans-serif;
        font-size: 11px;
        font-weight: 500;
        color: #444;
        margin-bottom: 3px;
      }

      .kr-total-amount-value {
        font-family: Roboto, sans-serif;
        font-size: 18px;
        font-weight: 400;
      }
    }

    // Iframe open
    &.kr-secure-iframe-visible {
      background-color: #f4f4f4;
      padding-top: 0;
      margin-bottom: 0;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      border-top: 0;
      border-right: 5px solid #f4f4f4;
      border-bottom: 5px solid #f4f4f4;
      border-left: 5px solid #f4f4f4;
      border-radius: 0;

      @include lock-icon.main;
    }
  }
}
