@use "../../variables" as *;
@use "./section";
@use "./option";
@use "../card-icons";
@use "../../fonts";
@use "../../../../mixins/smartform/grid/wallet";
@use "../../../../mixins/smartform/grid/method-option";

$option-items-spacing: 15px;
$icon-wrapper-width: 60px;

@mixin section() {
  .kr-sf-wallet {
    @include section.spacing(
      $gap: $smartform-methods-gap
    );
    @include section.label;
    @include section.error;

    &__methods {
      @include group;
    }
  }
}

@mixin group() {
  .kr-wallet-group {
    gap: $smartform-methods-gap;

    @include wallet-option;
  }
}

// Wallet method option
@mixin wallet-option() {
  .kr-method-btn {
    @include option.base($option-height: $smartform-option-height);

    // Inside button elements
    @include method-option.radio-btn(
      $size: $smartform-radio-button-size,
      $border-color: $smartform-spb-radio-border-color,
      $border-color-hover: $color-primary,
      $active-color: $color-primary,
      $margin: $option-items-spacing
    );
    @include option-icon;
    @include option-label;
    @include wallet.delete-btn(
      $icon-fill: $color-grey-icons
    );
    @include method-option.loading-animation($color-primary);

    // Variants
    @include method-option.hover($border-color: $color-primary);
    @include wallet.hover-delete(
      $color-error: $color-error,
      $smartform-radio-button-size: $smartform-radio-button-size,
      $smartform-spb-radio-border-color: $smartform-spb-radio-border-color
    );
    @include method-option.disabled(
      $border-color: $border-color-disabled,
      $font-color: $font-color-disabled
    );
  }
}

@mixin option-icon() {
  .kr-icon {
    min-width: $icon-wrapper-width;

    @include card-icons.brands($container-element: none);

    .kr-brand-icon.kr-cards {
      margin-right: 0;
    }
  }
}

@mixin option-label() {
  &__label,
  &__label span {
    @include fonts.default;

    transition: 0.3s color;
  }
}
