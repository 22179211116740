@use "../variables" as *;

@mixin popin() {
  .kr-wallet-tabs {
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: rgb(0, 0, 0, 2%) 0 3px 5px 0;

    .kr-wallet-tab {
      border: 0;
      border-radius: 0;
    }

    // Selected tab
    .kr-wallet-tab.kr-selected {
      border-bottom: 1px solid $color-primary;
      z-index: 1;

      &.kr-my-cards {
        box-shadow: rgb(0, 0, 0, 10%) 8px 2px 6px -6px;
      }

      &.kr-new-card {
        box-shadow: rgb(0, 0, 0, 10%) -8px 2px 6px -6px;
      }
    }

    // Not selected tab
    .kr-wallet-tab:not(.kr-selected) {
      border-bottom: 1px solid #e6e6e6;
    }
  }

  .kr-embedded.kr-wallet.kr-wallet-mycards {
    padding-top: 65px;
  }

  .kr-embedded.kr-wallet.kr-wallet-newcard {
    padding-top: 50px;
  }
}

@mixin embedded() {
  .kr-wallet-tabs {
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 15px;
    box-shadow: rgb(0, 0, 0, 10%) 0 2px 20px 0;

    .kr-wallet-tab {
      padding: 15px 5px;
      background-color: $color-light-secondary;
      border: 1px solid $color-grey-border;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      transition: all 0.2s;
      cursor: pointer;

      span {
        font-family: Roboto, sans-serif;
        font-size: 14px;
        transition: all 0.2s;
        cursor: pointer;
      }
    }

    // Selected tab
    .kr-wallet-tab.kr-selected {
      background-color: $color-light-secondary;
      border-bottom: 1px solid $color-primary;
      z-index: 1;

      &.kr-my-cards {
        box-shadow: rgb(0, 0, 0, 10%) 8px 2px 10px -3px;
      }

      &.kr-new-card {
        box-shadow: rgb(0, 0, 0, 10%) -8px 2px 10px -3px;
      }

      span {
        color: $color-primary;
      }
    }

    // Not selected tab
    .kr-wallet-tab:not(.kr-selected) {
      background-color: #e6e6e6;
      z-index: 0;

      span {
        color: #aaa;
      }

      &:hover span {
        color: $color-grey-icons-hover;
      }
    }
  }
}
