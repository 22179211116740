@mixin main($font-family: system-ui, $color: black) {
  .kr-sp-panel-separator {
    --kr-sp-font-family: var(--kr-white-label-sp-font-family, #{$font-family});
    --kr-sp-separator-color: #ddd;

    display: block;

    .kr-sp-panel-separarator-hr {
      display: block;
    }

    hr {
      display: block;
      border: 0;
      height: 1px;
      background: var(--kr-sp-separator-color);
    }
  }
}

@mixin smartform() {
  .kr-sp-panel-separator {
    padding: var(--kr-sp-seperator-padding-y) 0;
  }
}
