.kr-brand-buttons,
.kr-embedded .kr-brand-buttons {
  width: $smartform-width;
  margin-bottom: 20px;
  min-height: 40px;

  .kr-brand-button {
    .kr-brand-button-icon {
      margin: 0 5px;
      height: 40px;
      width: 60px;

      svg {
        opacity: 0.5;
        box-sizing: border-box;
        height: 40px;
        width: 60px;
        padding: 2px 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
    }

    // Amex
    &.kr-amex .kr-brand-button-icon svg {
      background-color: #006fce;
    }

    // CB, VISA
    &.kr-cb .kr-brand-button-icon svg,
    &.kr-visa .kr-brand-button-icon svg,
    &.kr-visa_electron .kr-brand-button-icon svg,
    &.kr-visa_debit .kr-brand-button-icon svg {
      padding: 0;
    }
  }

  .kr-brand-button .kr-brand-button-label {
    font-family: Roboto, sans-serif;
    color: $color-input-placeholder;
    font-size: 13px;
    padding-top: 5px;
    height: 15px;
  }

  .kr-brand-button.kr-selected {
    svg {
      opacity: 1;
      border: 1px solid #999;
    }

    .kr-brand-button-label {
      color: $color-input-font;
    }
  }

  .kr-brand-button:not(.kr-selected):hover {
    svg {
      opacity: 0.7;
      border: 1px solid #aaa;
    }

    .kr-brand-button-label {
      color: $color-input-font;
    }
  }
}
