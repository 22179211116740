@use "../variables" as *;
@use "../spinner";
@use "../smartform/split-payment";

@mixin -base-button() {
  .kr-payment-button {
    position: relative;
    box-sizing: border-box;
    background-color: $color-primary;
    width: 100%;
    height: $button-height;
    outline: none;
    transition: box-shadow 0.4s, background-color 0.4s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    border: 1px solid $color-primary;
    cursor: pointer;
    margin-bottom: 7px;

    // PreRender
    color: $color-light;
    font-family: Roboto, sans-serif;
    font-size: 14px;

    span {
      display: inline;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: $color-light;
      user-select: none;
      cursor: pointer;
      transition: color 0.1s, opacity 0.1s;
    }

    span.kr-discount-label {
      opacity: none;
    }

    @include spinner.main;

    // Loading state
    &.kr-loading {
      display: block;

      span {
        display: none;
      }

      .kr-spinner {
        display: block;
      }
    }

    &:disabled span {
      cursor: not-allowed;
    }

    @include split-payment.button-label;
  }

  // Focus
  .kr-payment-button:focus {
    box-shadow: rgb(0, 0, 0, 25%) 0 3px 15px;
  }
}

@mixin main() {
  @include -base-button;
}

@mixin root() {
  @at-root .kr-standalone-payment-button {
    @include -base-button;
  }
}
