@use "../../../../mixins/flex";
@use "../../../../mixins/smartform/card-tooltip";
@use "../../../no-theme/smartform/loading-animation";
@use "../../variables" as *;
@use "../card-icons";
@use "../wallet";

@mixin font() {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: $color-font;
}

@mixin label() {
  @include card-icons.main;
}

@mixin list-button() {
  .kr-methods-group-activator,
  .kr-method-label {
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid #b7b7b7;
    border-radius: 4px;
    box-shadow: rgb(149, 157, 165, 15%) 0 0 24px;
    height: 50px;

    @include wallet.label;
    @include wallet.delete-button-hover;
    @include card-icons.main;
    @include wallet.create-token;

    $exclude-list: ".kr-loading",
      ".kr-locked",
      ".kr-method-label--selected",
      ".kr-smart-form-wallet-card--hover-delete",
      ".kr-method-label--create-token-selected";

    &:hover:not(#{$exclude-list}) {
      border: 1px solid $color-primary;
      background-color: #fff;
    }

    &:focus:not(#{$exclude-list}) {
      border: 1px solid $color-primary-mid-light;
      background-color: #fff;
    }

    .kr-method-icon {
      display: flex;
      top: 0;
      flex-direction: column;
      justify-content: center;

      svg {
        fill: $color-primary;
      }

      @include card-icons.placeholder;
    }

    label {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: $color-font;
      transition: 0.3s color;
    }

    .kr-arrow-right svg {
      fill: $color-primary;
      display: none;
    }

    .kr-loading-animation {
      display: block;
      width: 20px;
      height: 20px;

      @include loading-animation.main($color-primary);

      i.kr-loader::before,
      i.kr-loader::after {
        border-radius: 2px;
      }
    }

    &.kr-loading {
      border: 1px solid $color-primary;
      background-color: #f4f4f4;
    }

    &.kr-locked {
      background-color: #fff;
      border: 1px solid #ddd;
      cursor: not-allowed;

      * {
        cursor: not-allowed !important;
      }

      label {
        color: #999;
        cursor: not-allowed;
      }
    }
  }

  .kr-method-label {
    @include label;
  }
}

@mixin group() {
  .kr-methods-group {
    .kr-methods-group-activator {
      display: flex;
      justify-content: flex-start;

      @include flex.column-gap(5px);
      @include card-tooltip.main($padding: 6px, $border-radius: 5px);

      .kr-method-icon {
        position: relative;
        display: flex;
        left: 0;
      }

      .kr-extra-brands {
        color: #777;
        font-family: Roboto, sans-serif;
        margin-left: 5px;
        font-size: 13px;
      }
    }
  }
}
