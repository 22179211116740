// Help modal
.kr-help-button-wrapper .kr-help-modal-wrapper .kr-help-modal {
  background-color: #fff;
  box-shadow: rgb(0, 0, 0, 30%) 0 19px 38px, rgb(0, 0, 0, 22%) 0 15px 12px;
  border-radius: 5px;

  // Header
  .kr-help-modal-header {
    background-color: #f4f4f4;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 35px;

    // Close button
    .kr-help-modal-close-button {
      width: 18px;
      height: 18px;

      svg {
        cursor: pointer;
      }

      svg path {
        fill: $color-primary;
      }
    }
  }

  // Content
  .kr-help-modal-content {
    margin-top: 35px;
  }

  .kr-help-modal-content > div.kr-help-content {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: $color-input-font;
  }

  .kr-help-modal-content > .kr-help-image,
  .kr-help-image-amex {
    margin-top: 25px;
  }
}
