@use "../../../../mixins/smartform/grid/back-button";
@use "../../variables" as *;

@mixin main() {
  @include back-button.main;

  .kr-back-button {
    @include back-icon;
  }

  // Hover efect
  &--hover .kr-back-button {
    @include hover;
  }
}

@mixin back-icon() {
  &__icon svg {
    color: $color-grey-icons;
  }
}

@mixin hover() {
  &__icon svg {
    color: $color-primary;
  }
}
