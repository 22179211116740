@use "./card-tooltip";
@use "./card-icons";
@use "./method-icons";
@use "../../../mixins/smartform/compact";

@mixin embedded() {
  @include compact.wallet;

  .kr-smart-form-methods {
    display: block; // see KJS-4247
    position: relative;
    width: 100%;
    z-index: 0;

    .kr-methods {
      z-index: 1;
      box-sizing: border-box;
      display: block;

      .kr-method-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        transition-delay: 0s, 0s;
        transition-property: opacity, max-height;
        opacity: 1;

        &--invisible {
          transition-delay: 0s, 0.5s;
          transition-duration: 0.5s, 0s;
          transition-property: opacity, max-height;
          opacity: 0;
          max-height: 0;
        }

        .kr-back-button {
          transition: all 0.3s;
        }

        &.kr-disabled-back-button .kr-back-button {
          display: none;
        }
      }
    }

    .kr-methods-list {
      .kr-methods-list-options {
        @include compact.list-options;

        .kr-method-label {
          @include card-icons.main;
        }

        .kr-methods-list-options-item {
          position: relative;
          display: block;
        }
      }

      // hover background
      .kr-method-label,
      .kr-methods-group-activator {
        border: none;
        outline: none;
        background-color: #fff;

        $exclude-list: ".kr-loading",
          ".kr-locked",
          ".kr-method-label--selected",
          ".kr-smart-form-wallet-card--hover-delete";

        &:hover:not(#{$exclude-list}),
        &:focus:not(#{$exclude-list}) {
          background-color: #eee;
        }
      }

      // No header on embedded mode
      .kr-methods-list-header {
        display: block;
        padding-top: 16px;
      }

      .kr-smart-form-list-section-name {
        display: block;
      }

      .kr-methods-group-activator,
      .kr-method-label {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 0;

        .kr-method-label-wrapper {
          position: static;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;

          label {
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 15px;
          }
        }

        &.kr-locked .kr-method-label-wrapper {
          cursor: not-allowed;
        }

        // Icon
        @include method-icons.main;

        // Label
        label {
          cursor: pointer;
        }

        .kr-methods-group-label {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        // Arrows
        .kr-arrow-left,
        .kr-arrow-right {
          position: absolute;
          height: 100%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          svg {
            fill: #444;
            width: 20px;
            height: 20px;
          }
        }

        .kr-arrow-right {
          right: 10px;
        }

        .kr-arrow-left {
          left: 10px;
        }

        // Loading
        &.kr-loading {
          cursor: wait;

          label {
            cursor: wait;
          }
        }

        // Locked
        &.kr-locked {
          cursor: default;
        }
      }
    }

    .kr-methods-list-options {
      .kr-methods-group {
        .kr-methods-group-activator {
          .kr-method-icon {
            margin-right: 0;
          }

          .kr-method-tooltip {
            position: relative;
          }

          @include card-tooltip.main;
        }
      }
    }

    // Cards with no back button - extra top padding
    .kr-methods.kr-method-active.kr-method-cards > .kr-disabled-back-button {
      &:not(.kr-method-content--invisible, .kr-secure-iframe-visible) {
        padding-top: 15px;
      }
    }
  }
}

@mixin popin() {
  $method-item-height: 40px;

  .kr-smart-form-methods {
    min-height: 350px;
    position: relative;
    width: 100%;
    overflow: visible;

    .kr-methods {
      z-index: 1;
      box-sizing: border-box;
      display: block;
      transition: all 0.3s;
      transition-delay: 0.2s;
    }

    .kr-methods-list {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;

      .kr-methods-group-activator,
      .kr-method-label {
        cursor: pointer;

        $exclude-list: ".kr-loading",
          ".kr-locked",
          ".kr-method-label--selected",
          ".kr-smart-form-wallet-card--hover-delete";

        &:hover:not(#{$exclude-list}) {
          background-color: #eee;
        }

        label {
          cursor: pointer;
        }
      }

      .kr-methods-list-header,
      .kr-methods-group-activator,
      .kr-method-label {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        .kr-method-icon {
          display: none;
        }

        .kr-arrow-left,
        .kr-arrow-right {
          position: absolute;
          height: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;

          svg {
            fill: #444;
            width: 20px;
            height: 20px;
          }
        }

        .kr-arrow-right {
          right: 0;
        }

        .kr-arrow-left {
          left: 10px;
        }
      }

      .kr-methods-group-activator,
      .kr-method-label {
        transition: color 0.1s;

        // Loading animation
        .kr-loading-animation {
          display: none;
        }

        // Loading
        &.kr-loading {
          border-top: 1px solid #808080;
          border-bottom: 1px solid #808080;
          background-color: #eee;
          cursor: wait;

          label {
            cursor: wait;
          }
        }

        // Locked
        &.kr-locked {
          color: #ddd;
          cursor: default;
        }
      }
    }
  }
}
