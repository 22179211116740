@use "./method-option" as option;
@use "../card-icons";

@mixin section() {
  .kr-sf-cards {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    @include option.main;
    @include option-btn;
    @include card-form;
  }
}

@mixin option-btn() {
  .kr-method-btn {
    @include card-icons.main;
  }
}

@mixin card-form() {
  .kr-card-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    :not(.kr-size-half) {
      grid-column-start: span 2;
    }

    .kr-size-half {
      grid-column-start: span 1;
      width: 100%;
    }
  }
}
