// Loading animation
@use "./variables" as *;
@use "./../no-theme/smartform/loading-animation";

@mixin main($size: 8px) {
  .kr-spinner {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @include loading-animation.main($color-primary);
    @include -frozen;

    i.kr-loader::before,
    i.kr-loader::after {
      width: $size;
      height: $size;
      background-color: $color-light;
      animation-duration: 1s;
      border-radius: 2px;
    }

    i.kr-loader::before {
      top: calc(50% - $size);
      left: calc(50% - $size);
    }
  }
}

@mixin -frozen() {
  &.kr-button-animation--frozen {
    i.kr-loader::before,
    i.kr-loader::after {
      animation: none;
    }
  }
}
