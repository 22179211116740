$option-border-radius: 4px;
$option-border: 1px solid #b7b7b7;
$option-shadow: rgba(149, 157, 165, 15%) 0 0 24px;

@mixin base($option-height) {
  height: $option-height;
  border: $option-border;
  border-radius: $option-border-radius;
  box-shadow: $option-shadow;
  background-color: #fff;
  transition: 0.3s all;
}
