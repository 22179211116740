$icon-width: 19px;
$icon-height: 15px;
$wrapper-width: 43px;

// Brand icon
.kr-pan .kr-icon .kr-icon-wrapper {
  .kr-icon-inner-wrapper .kr-icon-wrapper-content {
    // Standard icon
    svg {
      padding: 0 3px;
      box-shadow:
        rgb(0, 0, 0, 7%) 0 6px 24px 0,
        rgb(0, 0, 0, 40%) 0 0 0 1px;
      width: $icon-width + 10px;
      height: $icon-height + 6px;
      border-radius: 3px;
      box-sizing: border-box;
    }
  }

  &.kadeos_culture,
  &.kadeos_gift {
    .kr-icon-inner-wrapper .kr-icon-wrapper-content svg {
      height: $icon-height + 4px;
    }
  }

  &.prv_opt {
    .kr-icon-inner-wrapper .kr-icon-wrapper-content svg {
      height: $icon-height + 4px;
    }
  }

  &.naranja,
  &.elo {
    .kr-icon-inner-wrapper .kr-icon-wrapper-content svg {
      padding: 3px;
    }
  }

  // Icon exceptions
  &.default .kr-icon-inner-wrapper .kr-icon-wrapper-content svg {
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    width: $icon-width + 5px;
    height: $icon-height + 9px;

    path {
      fill: $color-grey-icons;
      transition: fill 0.2s;
    }
  }

  &[class*="amex"] .kr-icon-inner-wrapper .kr-icon-wrapper-content svg {
    background-color: #006fce;
  }

  &.cb,
  &.discover,
  &.diners,
  &.cabal,
  &.cabal_debit,
  &.aura,
  &.hiper,
  &.hipercard {
    .kr-icon-inner-wrapper .kr-icon-wrapper-content svg {
      padding: 0;
      height: $icon-height + 4px;
    }
  }

  &.visa .kr-icon-inner-wrapper .kr-icon-wrapper-content svg {
    padding: 0 1px;
  }

  &.visa_electron,
  &.visa_debit {
    .kr-icon-inner-wrapper .kr-icon-wrapper-content svg {
      padding: 0;
    }
  }
}

// Error
.kr-pan .kr-icon.kr-on-error .kr-icon-wrapper {
  &[class*="amex"],
  &.bancontact,
  &.cabal,
  &.carnet {
    .kr-icon-inner-wrapper .kr-icon-wrapper-content svg {
      background-color: #c83737;
    }
  }
}

// Adjust position with multibrand
.kr-field-element.kr-pan .kr-icon-wrapper-root.kr-multi-brand {
  .kr-icon .kr-icon-wrapper .kr-icon-inner-wrapper .kr-icon-wrapper-content {
    padding-right: 2px;
  }
}

.kr-pan {
  .kr-brand-selector .kr-brand-selector-wrapper ul.kr-brand-selector-list {
    display: block;
  }
}

/* Multibrand */
// Brand selector
.kr-pan .kr-icon-wrapper-root {
  display: flex;
  flex-direction: row;
  position: relative;
}

// Arrow
.kr-pan .kr-icon-arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 6px 0 0;
  width: 12px;
  z-index: 1;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    transition: transform 0.2s;
    cursor: pointer;

    path {
      fill: $color-grey-icons;
    }
  }

  &:hover svg path {
    fill: $color-grey-icons-hover;
  }

  &.kr-icon-arrow-turned svg {
    transform: rotate(180deg);
  }
}
