$animation-name-before: "kr-loader-before";
$animation-name-after: "kr-loader-after";

@mixin keyframes() {
  @keyframes #{$animation-name-before} {
    0%,
    100% {
      transform: none;
    }

    25% {
      transform: translateX(100%);
    }

    50% {
      transform: translateX(100%) translateY(100%);
    }

    75% {
      transform: translateY(100%);
    }
  }

  @keyframes #{$animation-name-after} {
    0%,
    100% {
      transform: none;
    }

    25% {
      transform: translateX(-100%);
    }

    50% {
      transform: translateX(-100%) translateY(-100%);
    }

    75% {
      transform: translateY(-100%);
    }
  }
}

@mixin main($color) {
  i.kr-loader {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &::before,
    &::after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      width: 5px;
      height: 5px;
      background-color: $color;
    }

    &::before {
      top: calc(50% - 5px);
      left: calc(50% - 5px);
      animation: #{$animation-name-before} 0.8s cubic-bezier(0, 0, 0.24, 1.21) infinite;
    }

    &::after {
      top: 50%;
      left: 50%;
      animation: #{$animation-name-after} 0.8s cubic-bezier(0, 0, 0.24, 1.21) infinite;
    }

    // For visual tests
    &.kr-loader--frozen {
      &::before,
      &::after {
        animation: none;
      }
    }
  }
}

@mixin overlay($color: white, $spinner-size: 20px) {
  @include main($color);

  i.kr-loader {
    height: $spinner-size;
    width: $spinner-size;
    position: relative;

    &::before,
    &::after {
      width: $spinner-size;
      height: $spinner-size;
      background-color: $color;
      animation-duration: 1s;
    }

    &::before {
      top: -50%;
      left: -50%;
    }
  }
}
