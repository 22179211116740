@use "../variables" as var;

@mixin popin() {
  .kr-back-button {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    width: 100%;
    border: none;
    outline: none;
    background-color: #fff;
    transition: all 1s;

    &:hover {
      background-color: #f4f4f4;
    }

    label {
      cursor: pointer;
      margin-left: 35px;
      transition: all 1s;
    }

    .kr-arrow-left {
      display: flex;
      left: 10px;
      position: absolute;
      height: 100%;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      svg {
        transition: all 1s;
        fill: var.$color-primary;
        height: 20px;
        width: 20px;
      }
    }
  }
}
