@use "../variables" as *;

@mixin popin() {
  .kr-wallet-card-list {
    .kr-card-list-wrapper {
      border-radius: $border-radius;
    }
  }
}

@mixin embedded() {
  .kr-wallet-card-list {
    margin: -15px 0 16px;

    .kr-card-list-wrapper {
      border: 1px solid $color-grey-border;
      margin-bottom: 4px;
      padding: 10px 12px 10px 15px;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;

      .kr-card-element,
      .kr-card-element .kr-delete-button,
      .kr-card-element .kr-delete-button svg,
      .kr-card-element .kr-card-label,
      .kr-card-element .kr-card-label label {
        cursor: pointer;
      }

      .kr-card-element {
        &:not(.kr-deleted) {
          padding: 12px 0;
        }

        &.kr-deleted {
          transition: all 0.5s;
          height: 0;
          padding: 0;
          opacity: 0;
        }

        // Card logo
        .kr-brand-icon svg {
          box-sizing: border-box;
          padding: 0 3px;
          width: 30px;
          height: 20px;
          border-radius: 3px;
          box-shadow:
            rgb(0, 0, 0, 7%) 0 6px 24px 0,
            rgb(0, 0, 0, 40%) 0 0 0 1px;
        }

        .kr-brand-icon.kr-loading-assets svg {
          padding: 0;
        }

        &.kr-cb .kr-brand-icon svg,
        &.kr-visa .kr-brand-icon svg,
        &.kr-visa_electron .kr-brand-icon svg,
        &.kr-visa_debit .kr-brand-icon svg {
          padding: 0;
        }

        &.kr-amex .kr-brand-icon {
          background-color: #0a6fcf;
        }

        i.fi-x-circle svg {
          width: 20px;
          height: 20px;
        }
      }

      // Delete button
      .kr-delete-button-wrapper .kr-delete-icon svg {
        width: 20px;
        height: 20px;

        path {
          fill: $color-grey-icons;
        }
      }

      .kr-delete-button-wrapper .kr-delete-icon:hover svg path {
        fill: $color-grey-icons-hover;
      }
    }
  }

  // Label + radio
  .kr-wallet-card-list .kr-card-list-wrapper .kr-card-element .kr-card-label {
    margin-left: 22px;

    input[type="radio"] {
      display: none;
    }

    label {
      position: relative;
      min-width: 150px;

      span {
        font-family: Roboto, sans-serif;
        font-size: 13px;
        color: $color-input-font;
      }

      span::before,
      span::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      span:hover {
        cursor: pointer;
      }

      span::before {
        left: -21.5px;
        width: 10px;
        height: 10px;
        background-color: $color-light;
        border-radius: 50%;
        border: 1px solid $color-input-font;
      }

      span::after {
        left: -18.5px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $color-light;
        transition: all 0.2s;
      }
    }

    input[type="radio"]:checked + label span::after,
    input[type="radio"]:checked:hover + label span::after {
      background-color: $color-input-font;
    }

    input[type="radio"]:hover + label span::after {
      background-color: #eee;
    }
  }
}
