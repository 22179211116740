@mixin main() {
  .kr-currency-conversion {
    order: 31; /* After payment button */

    span {
      font-size: 14px;

      strong {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}
