@use "../variables" as var;
@use "./methods-list";
@use "../popin/header";
@use "../popin/footer";
@use "./modal_back_button" as back-button;

.kr-smart-form-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-height: 800px) {
    padding: 20px 0;
    justify-content: flex-start;
  }

  .kr-smart-form-modal-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #232323;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.25s ease-out;

    &.kr-modal-open {
      opacity: 0.7;
    }
  }

  .kr-smart-form-modal {
    min-width: var.$modal-width;
    z-index: 2;
    background-color: #fff;
    box-sizing: border-box;
    padding-bottom: var.$footer-height;
    padding-top: var.$header-height + var.$modal-content-top-margin;
    position: relative;
    box-shadow:
      rgb(0, 0, 0, 30%) 0 19px 38px,
      rgb(0, 0, 0, 22%) 0 15px 12px;
    opacity: 0;
    transform: scale(0.7);
    transition: all 0.15s ease-out;
    transition-property: opacity, transform;

    &.kr-modal-open {
      opacity: 1;
      transform: scale(1);
    }

    &.kr-secure-iframe-open,
    &.kr-secure-iframe-visible {
      padding-top: 30px;
      padding-bottom: 0;

      @import "./mobile";
    }

    @include methods-list.embedded;
    @include back-button.popin;
    @include header.smartform;
    @include footer.main;

    &.kr-fullscreen,
    &.kr-fullscreen .kr-smart-form-methods {
      width: 100%;
      height: 100%;

      .kr-method-active.kr-secure-iframe-open {
        width: 100%;
        height: 100%;
      }
    }
  }

  @import "../../../mixins/smartform/modal";
  @include modal-container(var.$modal-width);
}

&.kr-secure-iframe-visible {
  z-index: 10000;
}
