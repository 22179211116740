@use "./popin/header";

@mixin -header() {
  @include header.main;

  .kr-popin-modal-header {
    position: relative;
  }
}

@mixin -modal() {
  .kr-popin-redirection-modal {
    border: 5px solid #f4f4f4;
    border-radius: 5px;
    padding-top: 0;
    width: auto;
    height: auto;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 30%) 0 19px 38px, rgba(0, 0, 0, 22%) 0 15px 12px;
  }
}

@mixin main() {
  .kr-popin-redirection {
    @include -header;
    @include -modal;
  }
}
