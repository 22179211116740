@use "../variables" as *;
@use "./../spinner";

@mixin legacy() {
  .kr-popin-button {
    width: $smartbutton-width;

    @include -base;
  }
}

@mixin smartform() {
  .kr-smart-form-modal-button {
    @include -base;
  }
}

@mixin grid() {
  .kr-smart-form-modal-button {
    @include -base;

    span {
      line-height: 18px;
    }
  }
}

@mixin -base() {
  position: relative;
  box-sizing: border-box;
  padding: 15px;
  background-color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 4px;
  box-shadow: rgb(149, 157, 165, 15%) 0 0 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  user-select: none;
  outline: none;
  cursor: pointer;

  span {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: $color-light;
    user-select: none;
    line-height: normal;
    vertical-align: middle;
    cursor: pointer;
  }

  // Loading state
  &.kr-loading {
    display: block;
    height: 50px;

    span {
      display: none;
    }

    .kr-spinner {
      display: block;
    }
  }

  &.kr-disabled {
    background-color: $color-primary-mid-light;
    cursor: not-allowed;

    span {
      color: #dedede;
      cursor: not-allowed;
    }
  }

  @include spinner.main;
}
