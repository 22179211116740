@use "./variables" as *;

@mixin -default-panel {
  .kr-discount-panel.kr-discount-panel--default {
    .kr-discount-panel-content {
      overflow: hidden;
      max-height: 0;
      display: none;

      &--visible {
        display: flex;
        animation: 0.8s linear 0s slidedown;
        animation-fill-mode: forwards;
        padding-bottom: 16px;
      }
    }
  }
}

@mixin -base-inner-panel {
  .kr-discount-panel-content,
  .kr-discount-panel-content * {
    color: #777;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }

  .kr-discount-panel-subtotal,
  .kr-discount-panel-discount {
    padding: 4px;
  }

  .kr-discount-panel-subtotal-label,
  .kr-discount-panel-discount-label {
    font-weight: 500;
    color: $color-input-font;
  }

  .kr-discount-panel-subtotal-value,
  .kr-discount-panel-discount-value {
    color: #777;
  }

  .kr-discount-panel-discount {
    &--positive .kr-discount-panel-discount-value {
      color: $color-error;
    }
  }
}

@keyframes slidedown {
  from {
    max-height: 0;
  }

  to {
    max-height: 300px;
  }
}

@mixin embedded {
  .kr-discount-panel-wrapper {
    @include -default-panel;
    @include -base-inner-panel;
  }

  .kr-discount-panel--container {
    margin: 16px 0;
  }
}

@mixin root {
  @at-root .kr-discount-panel {
    @include -base-inner-panel;
  }
}
