$icon-width: 19px;
$icon-height: 15px;
$wrapper-width: 43px;

// Regular icon
.kr-field-element:not(.kr-security-code) .kr-icon-wrapper-root,
.kr-field .kr-icon-wrapper-root {
  display: block;
  height: 100%;
}

.kr-field-element:not(.kr-security-code) .kr-icon {
  display: block;
  z-index: 1;
}

.kr-field-element:not(.kr-security-code) .kr-icon .kr-icon-wrapper,
.kr-field .kr-icon .kr-icon-wrapper {
  display: block;
  height: 100%;

  .kr-icon-inner-wrapper {
    display: block;
    height: 100%;
  }

  .kr-icon-wrapper-content {
    width: $wrapper-width;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

// Expiry
.kr-field-element:not(.kr-pan, .kr-security-code) .kr-icon,
.kr-field .kr-icon {
  .kr-icon-wrapper .kr-icon-wrapper-content {
    // Standard icon
    svg {
      height: $icon-height + 7px;

      path {
        fill: $color-grey-icons;
        transition: fill 0.2s;
      }
    }

    // Custom field
    i {
      color: $color-grey-icons;
    }
  }

  &.kr-error .kr-icon-wrapper .kr-icon-wrapper-content i {
    color: $color-error;
  }
}

// Custom field text
.kr-field.kr-text {
  .kr-icon {
    order: 1;
  }
}
