@import "../../base/custom_checkbox";

.kr-field.kr-checkbox.kr-checkbox-type-switch {
  box-shadow: none;

  // Label
  label.kr-label,
  .kr-label label {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: $color-input-font;
    padding-left: 8px;
    user-select: none;
    transition: color 0.2s ease-in-out;
  }

  .kr-field-wrapper .kr-checkbox-input-wrapper .kr-field-control.kr-checkbox {
    // Switch
    .kr-checkbox-layout .kr-checkbox-control-indicator,
    .kr-checkbox-control-indicator {
      width: 26px;
      height: 12px;
      background-color: #ccc;

      &::before {
        height: 16px;
        width: 16px;
        left: -3px;
        bottom: -3px;
        background-color: #ddd;
        border: 1px solid #bbb;
      }
    }

    // Checked
    .kr-checkbox-layout input:checked + .kr-checkbox-control-indicator,
    input:checked + .kr-checkbox-control-indicator {
      background-color: var(--kr-form-checkbox-light-backgroundColor);

      &::before {
        background-color: var(--kr-form-checkbox-backgroundColor);
        border: 1px solid var(--kr-form-checkbox-backgroundColor);
      }
    }
  }
}
