@mixin main($padding: 6px, $border-radius: 5px) {
  .kr-method-tooltip-content {
    display: flex;
    opacity: 0;
    transform: scale(0);
    transition: all 0.15s ease-out;
    transition-property: opacity, transform, top;
    background-color: transparent;

    &.kr-visible {
      opacity: 1;
      transform: scale(1);
    }

    &.kr-on-top {
      top: -53px;
    }

    .kr-card-tooltip,
    .kr-methods-group-tooltip {
      position: absolute;
      top: 0;
      display: flex;
      padding: $padding;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: $border-radius;
      box-shadow:
        rgb(50, 50, 93, 25%) 0 13px 27px -5px,
        rgb(0, 0, 0, 30%) 0 8px 16px -8px;

      span {
        margin: 4px 3px;
      }

      span svg {
        cursor: pointer;
      }
    }
  }
}
